<template>
  <div class="row pr cinzento1 m-0">
    <iframe id="talaoPrint" name="talaoPrint" style="visibility:hidden;position:absolute;top:-10000px;width:1px;height:1px;"></iframe>
    <modal :clickToClose="false" name="opcoesEventos" height="auto" :scrollable="scrollable" :adaptive="true" @before-open="scrollable = true" @before-close="scrollable = false" style="max-height: 100vh;overflow-y: auto;">
      <div class="'panel m-0" v-if="eventoInfo.id">
        <div :class="'panel-heading pr ' + (form.livre ? 'azull' : form.cor)" style="border-bottom: solid 1px #000;">
          <button @click="$modal.hide('opcoesEventos');" class="float-right btn btn-pure btn-md"><i class="icon wb-close"></i></button>
          <div class="clearfix"></div>
          <h3 :class="{'panel-title text-uppercase text-center': 1, 'texto-branco': form.cor !== 'Amarelo' && form.cor !== 'Branco' && form.cor !== undefined }" style="font-size:21px;font-weight:bold;">{{form.livre ? 'Entrada Livre' : form.nome}}</h3>
        </div>
        <div class="panel-body pt-40 row">
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Cacifo</span>
              <input :type="form.livre? 'text' : 'number'" class="form-control" v-model="form.cacifo" />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="checkbox-custom">
              <input type="checkbox" id="concluico" v-model="form.fim">
              <label for="concluico">Concluído</label>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="!form.livre">
            <button class="input-group-addon btn btn-success texto-branco btn-block" @click="imprimirFN()">Imprimir Lista Convidados</button>
          </div>
          <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
              <b>Crianças</b><br />
              <div v-if="form.livre">
                <div style="width:24%;margin-right:2px; margin-top:4px;float:left;" v-for="(dt, index) in form.criancas" :key="index">{{form.criancas[index]}}</div>
              </div>
              <div v-else>
                <div style="width:49%;margin-right:2px; margin-top:4px;float:left;" v-for="(dt, index) in form.criancas" :key="index">
                  <input style="width: 86%;float:right; font-size:20px;" class="form-control" v-model="form.criancas[index]" />
                  <span style="font-size:20px;font-weight:bold;float:right;">{{cacifoFN(parseInt(form.cacifo) || 1, index)}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-lg-12 mt-40">
            <div class="input-group">
              <span class="input-group-addon">Observações Cacifo</span>
              <textarea row="3" type="text" class="form-control" v-model="form.ob1"></textarea>
            </div>
          </div>

          <a @click="atualizar()" class="btn btn-block btn-primary" href="javascript:void(0)">Guardar</a>
          <a @click="$modal.hide('opcoesEventos');" class="btn btn btn-block btn-default" href="javascript:void(0)">Cancelar</a>
        </div>
      </div>
    </modal>
    <div class="cinzento" style="width:100%">
      <a class="icon wb-power float-right m-10" href="javascript:void(0)" @click="logout()" >
        <span class="sr-only">Sair</span>
      </a>
      <img :src="$store.state.balizaslandia === 'smf' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:68px;" />
      <span style="color:#fff;font-size:20px;">{{parseInt(day)}} de {{$t('meses.' + parseInt(month))}} de {{year}}</span>

      <button v-if="!ocultas" @click="ocultas = 1" class="btn btn-success float-right">Ver Eventos Concluídos</button>
      <button v-else @click="ocultas = 0" class="btn btn-success float-right">Voltar</button>

      <span  v-if="ocultas" style="color:#fff;font-size:20px;margin-left: 200px;">EVENTOS CONCLUÍDOS</span>
    </div>
    <div class="cinzento1" style="width:100%">
      <div class="table-responsive cinzento1">
        <table class="table balizas">
          <tbody style="color:#000;">
            <tr style="background:#ddd">
              <td>
                <div style="width:300px;min-height: calc(100vh - 60px); position:relative; background:#fff; float:left; border: solid 1px #333;" v-for="(dt, index) in res" :key="index" :class="{'mao p-0': 1, 'cinzento1': meta(dt, 'saiu') }" @click="upData(dt, false, index)" v-show="!meta(dt, 'saiu') && !ocultas || meta(dt, 'saiu') && ocultas">
                  <div style="height:20px;" :class="meta(dt, 'Cor Pulseira') ? meta(dt, 'Cor Pulseira') : coresFN(dt.event_at.split(' ')[1])"></div>
                  <div style="background:#444; text-align:center; height:200px;">
                    <div style="height:120px;" :class="{'fa-blink': !meta(dt, 'saiu') && terminadaFN(saidaFN(meta(dt, 'Prolongamento festa (+1h)') && meta(dt, 'Prolongamento festa +30m') ? 3.5 : (meta(dt, 'Prolongamento festa (+1h)') ? 3 : (meta(dt, 'Prolongamento festa +30m') ? 2.5 : 2)), dt.event_at, dt, -5)) ?  1: 0}">
                      <span class="cab23 " v-if="meta(dt, 'menu') == 7">{{dt.eventable.name}}</span>
                      <div class="pr">
                        <span class="cab23 " v-html="meta(dt, 'Filho 1')" v-if="meta(dt, 'Filho 1') && meta(dt, 'check1')"></span><label style="font-size: 1.715rem;position: absolute; right:10px;top:0px;color:#fff;" v-html="meta(dt, 'Anos')" v-if="meta(dt, 'Anos') && meta(dt, 'check1')"></label>
                      </div>
                      <div class="pr">
                        <span class="cab23 " v-html="meta(dt, 'Filho 2')" v-if="meta(dt, 'Filho 2') && meta(dt, 'check2')"></span><label style="font-size: 1.715rem;position: absolute; right:10px;top:0px;color:#fff;" v-html="meta(dt, 'Anos 2')" v-if="meta(dt, 'Anos 2') && meta(dt, 'check2')"></label>
                      </div>
                      <div class="pr">
                        <span class="cab23 " v-html="meta(dt, 'Filho 3')" v-if="meta(dt, 'Filho 3') && meta(dt, 'check3')"></span><label style="font-size: 1.715rem;position: absolute; right:10px;top:0px;color:#fff;" v-html="meta(dt, 'Anos 3')" v-if="meta(dt, 'Anos 3') && meta(dt, 'check3')"></label>
                      </div>
                      <div class="pr">
                        <span class="cab23 " v-html="meta(dt, 'Filho 4')" v-if="meta(dt, 'Filho 4') && meta(dt, 'check4')"></span><label style="font-size: 1.715rem;position: absolute; right:10px;top:0px;color:#fff;" v-html="meta(dt, 'Anos 4')" v-if="meta(dt, 'Anos 4') && meta(dt, 'check4')"></label>
                      </div>
                      <span class="cab23" v-for="(x, ii) in dt.clonado" :key="index + '-' + ii">
                        <div class="pr"><span v-html="meta(x, 'Filho 1')" v-if="meta(x, 'Filho 1') && meta(x, 'check1')"></span><label style="position: absolute; right:10px;top:0px;color:#fff;" v-html="meta(x, 'Anos')" v-if="meta(x, 'Anos') && meta(x, 'check1')"></label></div>
                        <div class="pr"><span v-html="meta(x, 'Filho 2')" v-if="meta(x, 'Filho 2') && meta(x, 'check2')"></span><label style="position: absolute; right:10px;top:0px;color:#fff;" v-html="meta(x, 'Anos 2')" v-if="meta(x, 'Anos 2') && meta(x, 'check2')"></label></div>
                        <div class="pr"><span v-html="meta(x, 'Filho 3')" v-if="meta(x, 'Filho 3') && meta(x, 'check3')"></span><label style="position: absolute; right:10px;top:0px;color:#fff;" v-html="meta(x, 'Anos 3')" v-if="meta(x, 'Anos 3') && meta(x, 'check3')"></label></div>
                        <div class="pr"><span v-html="meta(x, 'Filho 4')" v-if="meta(x, 'Filho 4') && meta(x, 'check4')"></span><label style="position: absolute; right:10px;top:0px;color:#fff;" v-html="meta(x, 'Anos 4')" v-if="meta(x, 'Anos 4') && meta(x, 'check4')"></label></div>
                      </span>
                    </div>

                    <span :class="{'fa-blink': !meta(dt, 'saiu') && terminadaFN(saidaFN(meta(dt, 'Prolongamento festa (+1h)') && meta(dt, 'Prolongamento festa +30m') ? 3.5 : (meta(dt, 'Prolongamento festa (+1h)') ? 3 : (meta(dt, 'Prolongamento festa +30m') ? 2.5 : 2)), dt.event_at, dt, -5)) ? 1 : 0, 'cab23': 1}" style="border-top: solid 1px #fff;">{{dt.event_at.split(' ')[1].substring(0, 5)}} >> {{saidaFN(meta(dt, 'Prolongamento festa (+1h)') && meta(dt, 'Prolongamento festa +30m') ? 3.5 : (meta(dt, 'Prolongamento festa (+1h)') ? 3 : (meta(dt, 'Prolongamento festa +30m') ? 2.5 : 2)), dt.event_at, dt)}}</span>
                    <span class="cab23">C. Conf.: {{meta(dt, 'Nº Crianças Confirmadas')}}</span>
                  </div>

                  <div style="height: 550px; overflow-y: auto;" class="ml-5 mt-10" v-html="criancasFN(dt, index)"></div>

                  <div style="height:100px;width:100%;border-top: solid 1px #000; position:relative;">
                    <h2 class="text-center">&nbsp;{{meta(dt, 'Total Crianças')}}&nbsp;</h2>
                    <div class="ml-5 mr-5 text-center" style="display:block; height:40px;">
                      <b style="font-size:16px;" v-if="meta(dt, 'Pinhata', 'pinhata_tema1')" :class="{'fa-blink': terminadaFN(saidaFN(meta(dt, 'Prolongamento festa (+1h)') && meta(dt, 'Prolongamento festa +30m') ? 3.5 : (meta(dt, 'Prolongamento festa (+1h)') ? 3 : (meta(dt, 'Prolongamento festa +30m') ? 2.5 : 2)), dt.event_at, dt, -20)) ? 1 : 0}">{{meta(dt, 'Pinhata')}} - <span  class="verm m-0"> Sair às {{saidaFN(meta(dt, 'Prolongamento festa (+1h)') && meta(dt, 'Prolongamento festa +30m') ? 3.5 : (meta(dt, 'Prolongamento festa (+1h)') ? 3 : (meta(dt, 'Prolongamento festa +30m') ? 2.5 : 2)), dt.event_at, dt, -15)}}</span></b>
                    </div>
                    <!--<p style="position: absolute;bottom: 0px;margin-left: 46%;" v-if="meta(dt, 'saiu')" class="text-center" v-html="check()"></p>
                    <p style="position: absolute;bottom: 0px;margin-left: 46%;" v-else class="text-center fa-blink" v-html="terminadaFN(saidaFN(meta(dt, 'Prolongamento festa (+1h)') ? 3 : 2, dt.event_at, dt))"></p>-->
                  </div>
                  <div class="ml-10 mr-10" style="clear:both;">
                    <div :class="'float-left mr-10' + (meta(dt, 'Lanche') != 3 ? 'c-verde' : '') + (meta(dt, 'Lanche') == 2 ? 'c-amarelo' : '')"></div>
                    <div style="font-weight: bold;" class="text-center text-success" v-if="meta(dt, 'Lanche') == 3">Lanche Concluído</div>
                    <div style="font-weight: bold;" class="text-center text-warning" v-else-if="meta(dt, 'Lanche') == 2">Lanche a Decorrer</div>
                    <div style="font-weight: bold;" class="text-center" v-else>--</div>
                    <hr />
                    <b class="float-left">{{meta(dt, 'Observações Cacifo')}}</b>
                  </div>
                </div>
              </td>
              <td style="width:380px;">
                <table class="table balizas">
                  <thead>
                    <tr>
                      <th class="meio azul text-center">Cacifo</th>
                      <th class="meio azul text-center">Horário</th>
                      <th class="meio azul text-center">Nome</th>
                      <th class="meio azul text-center">OBS</th>
                    </tr>
                  </thead>
                  <tbody style="color:#000;">
                    <tr v-for="(dt, index) in res1" :key="index" :class="{'mao': 1 , 'cinzento1': meta(dt, 'saiu'), 'Vermelho': meta(dt, 'validado')}" @click="upData(dt, 1, index)" v-show="!meta(dt, 'saiu') && !ocultas || meta(dt, 'saiu') && ocultas">
                      <td class="text-center meio"><b>{{meta(dt, 'Cacifo')}}</b></td>
                      <td :class="{'text-center meio': 1, 'text-danger': new Date() >= new Date(dt.event_at.split(' ')[0] + ' ' + dt.saida)}">{{dt.event_at.split(' ')[1].substring(0, 5)}} > {{dt.saida}}</td>
                      <td class="text-center meio">{{meta(dt, 'Crianças')}}</td>
                      <td class="text-center meio"><a v-if="meta(dt, 'Observações Cacifo')" class="text-danger" style="font-weight:bold;">Ver</a></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { searchEvent, patchEventClient } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'pageCacifos',
  data () {
    return {
      res: [],
      res1: [],
      year: '',
      month: '',
      day: '',
      ano: new Date().getFullYear() + 1,
      dataMin: '2017-01-01',
      eventoInfo: {},
      form: {
        cacifo: '',
        criancas: []
      },
      scrollable: false,
      ocultas: 0
    }
  },
  mounted () {
    this.SET_LOAD(true)
    let a = new Date()
    this.month = ('0' + (1 + a.getMonth())).slice(-2)
    this.year = a.getFullYear()
    this.day = ('0' + a.getDate()).slice(-2)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    cacifoFN (n, index) {
      let limit = this.$store.state.balizaslandia === 'oaz' ? 168 : 128
      let a = n + index
      if (a > limit) {
        return a - limit
      }
      return a
    },
    init () {
      this.formUrl('', 1)
    },
    formUrl () {
      this.SET_LOAD(true)
      // 67
      let cal = []
      switch (this.$store.state.user.email) {
        case 'cacifos.smf@balizaslandia.pt':
          cal = [6, 67]
          break
        case 'cacifos.oaz@balizaslandia.pt':
          cal = [107, 110]
          break
      }
      searchEvent(this.axios, cal, this.year, this.month, '', '', '', this.day, '').then((res) => {
        let x = res.data.filter(a => !this.meta(a, 'Bloqueado') && a.meta[0] && parseInt(a.calendar_id) === cal[0]).sort((a, b) => new Date(a.event_at.replace(/-/g, '/')) - new Date(b.event_at.replace(/-/g, '/')))
        let i = 0
        let t = x.length
        let apagar = []
        while (i < t && x[ i + 1]) {
          if (x[i].event_at === x[i + 1].event_at) {
            if (!x[i].clonado) {
              x[i].clonado = []
            }
            x[i].clonado.push(x[i + 1])
            apagar.push(i + 1)
            if (x[i + 2]) {
              if (x[i].event_at === x[i + 2].event_at) {
                x[i].clonado.push(x[i + 2])
                apagar.push(i + 2)
                if (x[i + 3]) {
                  if (x[i].event_at === x[i + 3].event_at) {
                    x[i].clonado.push(x[i + 3])
                    apagar.push(i + 3)
                    ++i
                  }
                }
                ++i
              }
            }
          }
          ++i
        }
        i = apagar.length
        while (--i >= 0) {
          x.splice(apagar[i], 1)
        }
        /*
        x.forEach((a, index) => {
          if (!this.meta(a, 'Cacifo')) {
            if (!index) {
              let k = a.meta.findIndex(b => b.meta_key === 'Cacifo')
              if (k >= 0) {
                a.meta[k].meta_value = 1
              } else {
                a.meta.push({ 'meta_key': 'Cacifo', 'meta_value': 1})
              }
            } else {
              let k = a.meta.findIndex(b => b.meta_key === 'Cacifo')
              let kk = parseInt(this.meta(x[index - 1], 'Cacifo')) + parseInt(this.meta(x[index - 1], 'Nº Crianças Confirmadas'))
              if (kk > 128) {
                kk -= 128
              }
              if (k >= 0) {
                a.meta[k].meta_value = kk
              } else {
                a.meta.push({ 'meta_key': 'Cacifo', 'meta_value': kk})
              }
            }
          }
        })
        */
        this.res = x
        x = res.data.filter(a => !this.meta(a, 'Bloqueado') && parseInt(a.calendar_id) === cal[1]).map(a => {
          a.saida = this.saidaFN(this.meta(a, 'tempo') == 3 ? 3 : (this.meta(a, 'tempo') == 2 ? 2 : 1), a.event_at, a, 0)
          return a
        })
        this.res1 = x.sort((a, b) => a.saida.localeCompare(b.saida))
        this.SET_LOAD()
        /*
        _.delay(function (a) {
          a.formUrl()
        }, 100000, this)
        */
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    meta (dt, campo, campo1, virgula) {
      if (dt.id) {
        let a = dt.meta.find(a => a.meta_key === campo)
        if (a) {
          if (!a.meta_value) {
            return ''
          }
          if ((a.meta_value === 'true' || a.meta_value === '1' || a.meta_value === 1) && a.meta_key !== 'Cacifo' && a.meta_key !== 'menu' && a.meta_key !== 'Prolongamento festa +30m' && a.meta_key !== 'Prolongamento festa (+1h)') {
            if (campo1) {
              let b = dt.meta.find(x => x.meta_key === campo1)
              if (b) {
                return a.meta_key + ' -> ' + (b.meta_value || 'tema a confirmar') + (virgula && a.meta_value ? '/ ' : '')
              }
            }
            return a.meta_key + (virgula && a.meta_key ? '/ ' : '')
          } else {
            if (a.meta_value === '0' || a.meta_value === 0) {
              return ''
            }
            return a.meta_value + (virgula && a.meta_value ? '/ ' : '')
          }
        }
      }
      return ''
    },
    linhasFN (dt, index) {
      let t = this.res.length - 1
      if (index < (t - 1)) {
        if (this.res[index + 1].event_at === dt.event_at && this.res[index + 2].event_at === dt.event_at) {
          this.res[index + 1].ocultar = 1
          this.res[index + 2].ocultar = 1
          return 3
        }
      }
      if (index < t) {
        if (this.res[index + 1].event_at === dt.event_at) {
          this.res[index + 1].ocultar = 1
          return 2
        }
      }
      return 1
    },
    upData (dt, livre) {
      if (!dt.id) {
        return
      }
      this.$modal.show('opcoesEventos')
      this.eventoInfo = dt
      let nomes = []
      let criancas = this.meta(dt, 'Crianças').split(',')
      let cacifo = parseInt(this.meta(dt, 'Cacifo')) || ''
      if (!livre) {
        if (this.meta(dt, 'menu') === 'Centro Estudos') {
          nomes.push(dt.eventable.name)
        }
        if (this.meta(dt, 'Filho 1') && this.meta(dt, 'check1')) {
          nomes.push(this.meta(dt, 'Filho 1'))
        }
        if (this.meta(dt, 'Filho 2') && this.meta(dt, 'check2')) {
          nomes.push(this.meta(dt, 'Filho 2'))
        }
        if (this.meta(dt, 'Filho 3') && this.meta(dt, 'check3')) {
          nomes.push(this.meta(dt, 'Filho 3'))
        }
        if (this.meta(dt, 'Filho 4') && this.meta(dt, 'check4')) {
          nomes.push(this.meta(dt, 'Filho 4'))
        }
        let u
        let k
        if (dt.clonado) {
          u = dt.clonado.length
          k = 0
          while (k < u) {
            if (this.meta(dt.clonado[k], 'Filho 1') && this.meta(dt.clonado[k], 'check1')) {
              nomes.push(this.meta(dt.clonado[k], 'Filho 1'))
            }
            if (this.meta(dt.clonado[k], 'Filho 2') && this.meta(dt.clonado[k], 'check2')) {
              nomes.push(this.meta(dt.clonado[k], 'Filho 2'))
            }
            if (this.meta(dt.clonado[k], 'Filho 3') && this.meta(dt.clonado[k], 'check3')) {
              nomes.push(this.meta(dt.clonado[k], 'Filho 3'))
            }
            if (this.meta(dt.clonado[k], 'Filho 4') && this.meta(dt.clonado[k], 'check4')) {
              nomes.push(this.meta(dt.clonado[k], 'Filho 4'))
            }
            ++k
          }
        }
        if (criancas[0].trim() === '') {
          u = nomes.length
          k = 0
          while (k < u) {
            criancas[k] = nomes[k]
            ++k
          }
        }
        /*
        u = 0
        if (!criancas[2]) {
          if (index && cacifo < 2) {
            cacifo = 0
            while (this.res[u]) {
              k = parseInt(this.meta(this.res[u], 'Nº Crianças Confirmadas'))
              if (k < 11) {
                k = 10
              }
              if (this.res[u].clonado) {
                if (this.res[u].clonado.length === 1) {
                  if (k < 16) {
                    k = 15
                  }
                } else {
                  if (k < 21 && this.res[u].clonado.length > 1) {
                    k = 20
                  }
                }
              }
              cacifo += k
              if (cacifo > 128) {
                cacifo -= 128
              }
              if (index === ++u) {
                break
              }
            }
            ++cacifo
            if (cacifo > 128) {
              cacifo -= 128
            }
          } else {
            cacifo = 1
          }
        }
        */
      }

      this.form = {
        cacifo: cacifo,
        criancas: criancas,
        somar: (this.meta(dt, 'Filho 1') && this.meta(dt, 'check1') ? 1 : 0) + (this.meta(dt, 'Filho 2') && this.meta(dt, 'check2') ? 1 : 0) + (this.meta(dt, 'Filho 3') && this.meta(dt, 'check3') ? 1 : 0) + (this.meta(dt, 'Filho 4') && this.meta(dt, 'check4') ? 1 : 0),
        fim: this.meta(dt, 'saiu'),
        cor: this.meta(dt, 'Cor Pulseira') ? this.meta(dt, 'Cor Pulseira') : this.coresFN(dt.event_at.split(' ')[1]),
        nome: nomes.join(' / '),
        livre: livre,
        ob1: this.meta(dt, 'Observações Cacifo'),
        ob2: this.meta(dt, 'Observações Lanche'),
        nt: this.meta(dt, 'Total Crianças')
      }
      if (!livre) {
        let t = this.form.criancas.length
        let x = parseInt(this.meta(dt, 'Nº Crianças Confirmadas'))
        if (x < 11) {
          x = 10
        } else {
          if (dt.clonado) {
            if (dt.clonado.length === 1) {
              if (x < 16) {
                x = 15
              }
            } else {
              if (x < 21 && dt.clonado.length > 1) {
                x = 20
              }
            }
          }
        }
        while (t++ <= x) {
          this.form.criancas.push('')
        }
        this.form.criancas = this.form.criancas.splice(0, x)
      }
    },
    atualizar () {
      this.SET_LOAD(true)
      let t = 50 // this.form.criancas.length
      let tt = 0
      while (--t >= 0) {
        if (this.form.criancas[t]) {
          ++tt
          if (this.form.criancas[t].indexOf('/') !== -1) {
            ++tt
          }
        }
      }
      patchEventClient(this.axios, {
        id: this.eventoInfo.id,
        calendar_id: this.eventoInfo.calendar_id,
        color: this.eventoInfo.color,
        event_at: this.eventoInfo.event_at.substring(0, 16),
        meta: {
          'Cacifo': this.form.cacifo,
          'Total Crianças': tt,
          'Crianças': this.form.criancas.join(','),
          'saiu': this.form.fim,
          'Observações Cacifo': this.form.ob1
        }
      }).then(() => {
        this.SET_TOAST({ msg: 'Atualizado com sucesso.' })
        this.SET_LOAD()
        this.$modal.hide('opcoesEventos')
        this.formUrl()
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    logout () {
      this.$auth.logout({
        makeRequest: false,
        params: {},
        redirect: '/login',
        success: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
          // window.location.assign('/')
          // window.location = window.location.protocol + '//' + window.location.hostname
        },
        error: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
        }
      })
    },
    coresFN (a) {
      if (this.$store.state.balizaslandia === 'smf') {
        switch (a) {
          case '09:00:00':
            return 'Branco'
          case '09:01:00':
            return 'Laranja'
          case '09:30:00':
            return 'Preto'
          case '09:45:00':
            return 'Rosa'
          case '10:00:00':
            return 'Amarelo'
          case '10:30:00':
            return 'Azul'
          case '10:45:00':
            return 'Branco'
          case '11:30:00':
            return 'Vermelho'
          case '11:31:00':
            return 'Verde'
          case '12:00:00':
            return 'Castanho'
          case '12:30:00':
            return 'Roxo'
          case '12:45:00':
            return 'Cinzento'
          case '14:00:00':
            return 'Branco'
          case '14:01:00':
            return 'Laranja'
          case '14:15:00':
            return 'Preto'
          case '14:30:00':
            return 'Rosa'
          case '15:30:00':
            return 'Amarelo'
          case '17:00:00':
            return 'Azul'
          case '17:15:00':
            return 'Vermelho'
          case '17:30:00':
            return 'Verde'
          case '18:00:00':
            return 'Castanho'
          case '18:01:00':
            return 'Roxo'
        }
      }
      if (this.$store.state.balizaslandia === 'oaz') {
        switch (a) {
          case '09:00:00':
            return 'Branco'
          case '09:15:00':
            return 'Laranja'
          case '09:30:00':
            return 'Preto'
          case '09:45:00':
            return 'Rosa'
          case '10:00:00':
            return 'Amarelo'
          case '10:30:00':
            return 'Azul'
          case '11:30:00':
            return 'Vermelho'
          case '10:45:00':
            return 'Branco'
          case '11:45:00':
            return 'Verde'
          case '12:00:00':
            return 'Castanho'
          case '12:30:00':
            return 'Roxo'
          case '12:45:00':
            return 'Cinzento'
          case '14:00:00':
            return 'Branco'
          case '14:15:00':
            return 'Laranja'
          case '14:30:00':
            return 'Preto'
          case '15:00:00':
            return 'Rosa'
          case '15:30:00':
            return 'Amarelo'
          case '17:00:00':
            return 'Azul'
          case '17:15:00':
            return 'Vermelho'
          case '17:30:00':
            return 'Verde'
          case '17:45:00':
            return 'Castanho'
          case '18:00:00':
            return 'Roxo'
        }
      }
    },
    corFN (a) {
      switch (parseInt(a)) {
        case 2:
          return 'c-amarelo'
        case 3:
          return 'c-verde'
      }
      return ''
    },
    saidaFN (horas, dta, dt, x) {
      let dd = new Date(dta.replace(/-/g, '/'))
      if (horas === 2.5 || horas === 3.5) {
        dd.setMinutes(dd.getMinutes() + 30)
      }
      if (parseInt(this.meta(dt, 'menu')) === 7) {
        dd.setHours(dd.getHours() + 2)
      } else {
        dd.setHours(dd.getHours() + parseInt(horas))
      }
      if (this.meta(dt, 'menu') !== '7' && parseInt(dt.calendar_id) !== 67  && parseInt(dt.calendar_id) !== 110) {
        dd.setMinutes(dd.getMinutes() + 15)
      }
      if (x) {
        dd.setMinutes(dd.getMinutes() + x)
      }
      return dd.getHours() + ':' + ('0' + dd.getMinutes()).slice(-2)
    },
    terminadaFN (saida) {
      let a = new Date(this.year + '-' + this.month + '-' + this.day + ' ' + saida)
      if (new Date() > a) {
        return '<svg style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><circle style="fill:#eeee00;" cx="25" cy="25" r="25"/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'
      }
      return ''
    },
    check () {
      return '<svg style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><circle style="fill:#25AE88;" cx="25" cy="25" r="25"/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'
    },
    criancasFN (dt, index) {
      let limit = this.$store.state.balizaslandia === 'oaz' ? 168 : 128
      let i = parseInt(this.meta(dt, 'Cacifo'))
      if (!i) {
        let u = dt.meta.findIndex(a => a.meta_key === 'Cacifo')
        let c = 1
        if (index) {
          let xk = parseInt(this.meta(this.res[index - 1], 'Nº Crianças Confirmadas'))
          if (xk < 10) {
            xk = 10
          }
          if (this.res[index - 1].clonado) {
            if (this.res[index - 1].clonado.length === 1) {
              if (xk < 16) {
                xk = 15
              }
            } else {
              if (xk < 21 && this.res[index - 1].clonado.length > 1) {
                xk = 20
              }
            }
          }
          c = parseInt(this.meta(this.res[index - 1], 'Cacifo')) + xk
          if (c > limit) {
            c -= limit
          }
        }
        if (u >= 0) {
          dt.meta[u].meta_value = c
        } else {
          dt.meta.push({ 'meta_key': 'Cacifo', 'meta_value': c })
        }
        i = c
      }
      let x = ''
      let t = this.meta(dt, 'Crianças').split(',')
      let u = 0
      while (t[u] || t[u + 1] || t[u + 2]) {
        // .toString().split(' ')[0]
        x += '<h4 style="width:47%;float:left;"><b>' + i + '</b>. ' + t[u++] + '</h4>'
        if (++i > limit) {
          i = 1
        }
      }
      return x
    },
    imprimirFN () {
      let criancas = ''
      this.form.criancas.forEach((a, i) => {
        criancas += (parseInt(this.form.cacifo) + i) + ': ' + a + '<br />'
      })
      let doc = document.getElementById('talaoPrint').contentWindow.document
      doc.open()
      doc.write('<html><head></head><body>Festa <b>' + this.form.nome + '</b> <br />' + criancas + '<br />Total de Crianças: ' + this.form.nt + '<br /></body></html>')
      doc.close()
      _.delay(function () {
        document.getElementById('talaoPrint').contentWindow.focus()
        document.getElementById('talaoPrint').contentWindow.print()
      }, 500, this)
    }
  }
}
</script>
