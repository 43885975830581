<template>
  <div @click="submenu = false">
    <load-load></load-load>
      <div class="menuTelemovel" v-if="mobile && $store.state.user.email !== 'landiastaff@balizaslandia.pt' ">
        <div class="navbar-brand">
          <router-link to="/" ><img class="navbar-brand-logo" :src="$store.state.agendx1" style="width:95%"></router-link>
        </div>
        <a @click="mobile=false" href="javascript:void(0)" class="icon wb-close fechar"></a>

        <div class="clearfix"></div>
        <p v-for="(dt, index) in menu" :key="index">
          <router-link class="text-uppercase" :to="dt.link"><b>{{dt.title}}</b></router-link>
        </p>
        <p v-for="(dt, index) in submenu1" :key="index">
          <router-link class="text-uppercase" :to="dt.link"><b>{{dt.title}}</b></router-link>
        </p>
        <p v-if="$store.state.user">
          <router-link class="text-uppercase" v-if="$store.state.user.company.module_birthday" to="/aniversarios"><b>{{$t('menu.aniversarios')}}</b></router-link>
        </p>
        <p v-for="(dt, index) in submenu2" :key="index">
          <router-link class="text-uppercase" :to="dt.link"><b>{{dt.title}}</b></router-link>
        </p>

        <p v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
          <router-link class="text-uppercase" to="/monitores">MONITORES</router-link>
        </p>
        <p v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
          <router-link class="text-uppercase" to="/pagamentos">Pagamentos</router-link>
        </p>
        <p v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com'">
          <router-link class="text-uppercase" to="/relatorios">Relatórios</router-link>
        </p>

        <p>
          <a href="javascript:void(0)" @click="logout()"><b>{{$t('menu.sair')}}</b></a>
        </p>
      </div>

      <div class="menuTelemovel" v-if="notificacoesX">
        <a @click="notificacoesX=false" href="javascript:void(0)" class="icon wb-close fechar"></a>
        <div class="pr">
          <h3 class="float-left text-left p-10 c-fff" style="position:relative;">{{$t('menu.notificacoes')}}<!--<span class="badge badge-round badge-danger notificacoesMovel">{{notificacoesTotal}}</span>--></h3>
        </div>
        <div class="clearfix"></div>
        <div class="list-group">
          <div data-role="container">
            <div data-role="content" id="notificacoes">
              <a v-for="(dt,index) in notificacoesRes" :key="index" class="list-group-item dropdown-item" href="javascript:void(0)" role="menuitem">
                <div class="media">
                  <i class="icon wb-order bg-red-600 white icon-circle"></i>
                  <h6 class="media-heading">{{dt.text}}</h6>
                  <time class="media-meta">{{dt.updated_at}}</time>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <v-dialog></v-dialog>

      <modal name="termosCondicoes" height="auto" :scrollable="scrollable" :adaptive="true" @before-open="scrollable = true" @before-close="scrollable = false">
        <div style="background-color: #f1f1f1;padding: 25px;box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;">
          <h3 style="padding-top:0px;margin-top:0px;">{{$t('registar.termos_titulo')}}</h3>
          <div class="notranslate text-justify" v-html="$t('registar.termos')" style="width: auto;background-color: #fff;padding: 8px 12px;border-left: 4px solid #4CAF50;word-wrap: break-word;">
          </div>
        </div>
      </modal>

      <modal name="opcoesApagarCategorias" height="auto" :scrollable="scrollable" :adaptive="true" @before-open="scrollable = true" @before-close="scrollable = false">
        <div class="panel m-0">
          <div class="panel-heading">
            <h3 class="panel-title">{{$t('calendarios.apagar_categoria')}}</h3>
          </div>
          <div class="panel-body">
            <button @click="$modal.hide('opcoesApagarCategorias')" class="btn btn-block btn-default">{{$t('cancelar')}}</button>
            <button @click="apagarCategoriaModal('delete_all')" class="btn btn-block btn-danger">{{$t('calendarios.apagar_categoria_eventos')}}</button>
            <button @click="apagarCategoriaModal('all_null')" class="btn btn-block btn-warning">{{$t('calendarios.apagar_so_categoria')}}</button>
            <div class="clearfix"></div>
            <select class="form-control" style="margin-top:8px" v-model="apagarCategoriaEvento" name="event12321" @change="apagarCategoriaModal('transfer_events', apagarCategoriaEvento)" v-if="$store.state.apagarCategoria">
              <option value="">{{$t('calendarios.associar_Eventos_Categorias')}}</option>
              <option v-for="(dt, index) in $store.state.calendarios" :key="index" :value="dt.id" v-show="dt.active && dt.id !== $store.state.apagarCategoria.id">{{dt.name}}</option>
            </select>
          </div>
        </div>
      </modal>

      <div v-if="$auth.check() && !scrollable && !mobile && !notificacoesX && $store.state.user1" class="dashboard">
        <div v-if="$store.state.user.email === 'rececao.smf@balizaslandia.pt' || $store.state.user.email === 'rececao.oaz@balizaslandia.pt' || $store.state.user.email === 'lanche.smf@balizaslandia.pt' || $store.state.user.email === 'lanche.oaz@balizaslandia.pt' || $store.state.user.permissions.is_monitor == 1 || $store.state.user.email === 'cacifos.smf@balizaslandia.pt' || $store.state.user.email === 'cacifos.oaz@balizaslandia.pt' || $store.state.user.email === 'monitores.smf@balizaslandia.pt' || $store.state.user.email === 'monitores.oaz@balizaslandia.pt'">
          <monitor v-if="$store.state.user.permissions.is_monitor == 1"></monitor>
          <lanche v-if="$store.state.user.email === 'lanche.smf@balizaslandia.pt' || $store.state.user.email === 'lanche.oaz@balizaslandia.pt'"></lanche>
          <rececao v-if="$store.state.user.email === 'rececao.smf@balizaslandia.pt' || $store.state.user.email === 'rececao.oaz@balizaslandia.pt'"></rececao>
          <cacifo v-if="$store.state.user.email === 'cacifos.smf@balizaslandia.pt' || $store.state.user.email === 'cacifos.oaz@balizaslandia.pt'"></cacifo>

          <monitor-consulta v-if="$store.state.user.email === 'monitores.smf@balizaslandia.pt' || $store.state.user.email === 'monitores.oaz@balizaslandia.pt'"></monitor-consulta>
        </div>

        <div v-else>
          <nav class="site-navbar navbar navbar-default navbar-fixed-top navbar-mega" v-if="$store.state.user.company.id != 5 || ($store.state.user.company.id == 5 && routerFN() &&  $store.state.user.email !== 'landiastaff@balizaslandia.pt') && $route.name !== 'frase'" role="navigation">
            <div class="navbar-header">

              <button @click="mobile=true;" type="button" class="navbar-toggler collapsed float-left m-0 px-8" data-target="#site-navbar-collapse" data-toggle="collapse">
                <i class="icon wb-more-horizontal"></i>
              </button>
              <button @click="notificacoesX=true;" type="button" class="navbar-toggler collapsed float-left m-0 px-8" data-target="#site-navbar-collapse" data-toggle="collapse">
                <i class="icon wb-bell"></i>
                <span class="badge badge-round badge-danger notificacoesMovel">{{notificacoesTotal}}</span>
              </button>

              <!--<button v-if="$store.state.calendarios.length" @click="SET_CRIAREVENTO({criar: 1, data: {}});addEvent = true" type="button" class="navbar-toggler collapsed float-right m-0 px-8" data-target="#site-navbar-collapse" data-toggle="collapse">
                <i class="icon wb-plus-circle"></i>
              </button>-->

              <div class="navbar-brand navbar-brand-center site-gridmenu-toggle" data-toggle="gridmenu">
                <a @click="logoFN()" class="mao"><img class="navbar-brand-logo" :src="$store.state.agendx1" title="AgendX"></a>
                </div>
            </div>
            <div class="navbar-container container-fluid" style="margin-left:0px;">
              <div :class="'collapse navbar-collapse navbar-collapse-toolbar ' + (mobile ? 'show' : '')" id="site-navbar-collapse">
                <ul class="nav navbar-toolbar">
                  <li class="nav-item" v-for="(dt, index) in menu" :key="index" @click="mobile=false">
                    <router-link class="nav-link text-uppercase" :to="dt.link"><i :class="'icon ' + dt.icon"></i><span class="hidden-sm-down hidden-md-down text-uppercase"> {{dt.title}}</span></router-link>
                  </li>
                  <li :class="'nav-item dropdown dropdown-fw dropdown-mega menu ' + (submenu?'show':'')">
                    <a @click.stop="submenu=!submenu;" class="nav-link text-uppercase" data-toggle="dropdown" href="javascript:void(0)" aria-expanded="false" data-animation="fade" role="button">{{$t('menu.opcoes')}} <i :class="' ' +  (submenu?'wb-chevron-up-mini':'wb-chevron-down-mini')"></i></a>
                    <div class="dropdown-menu" role="menu" v-if="$store.state.user">
                      <div class="mega-content" v-if="$store.state.user.company">
                        <div class="row">
                          <div class="col-md-4">
                            <h5 class="text-uppercase">{{$t('menu.configuracoes')}}</h5>
                            <span v-for="(dt, index) in submenu1" :key="index"><router-link @click="mobile=false" class="nav-link p-5" :to="dt.link"><i class="wb-chevron-right-mini"></i> {{dt.title}}</router-link></span>
                          </div>
                          <div class="col-md-4" v-if="$store.state.user.company.module_birthday">
                            <h5 class="text-uppercase">{{$t('menu.modulos')}}</h5>
                            <router-link v-if="$store.state.user.company.module_birthday" @click="mobile=false" class="nav-link p-5 text-uppercase" to="/aniversarios"><i class="wb-chevron-right-mini"></i> {{$t('menu.aniversarios')}}</router-link>
                          </div>
                          <div class="col-md-4">
                            <h5>{{$t('menu.definicoes')}}</h5>
                            <span v-for="(dt, index) in submenu2" :key="index"><router-link @click="mobile=false" class="nav-link p-5 text-uppercase" :to="dt.link"><i class="wb-chevron-right-mini"></i> {{dt.title}}</router-link></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" @click="mobile=false" v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
                    <router-link class="nav-link text-uppercase" to="/monitores"><span class="hidden-sm-down hidden-md-down text-uppercase"> Monitores</span></router-link>
                  </li>
                  <li class="nav-item" @click="mobile=false" v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
                    <router-link class="nav-link text-uppercase" to="/pagamentos"><span class="hidden-sm-down hidden-md-down text-uppercase"> Pagamentos</span></router-link>
                  </li>
                  <li class="nav-item" @click="mobile=false" v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com'">
                    <router-link class="nav-link text-uppercase" to="/relatorios"><span class="hidden-sm-down hidden-md-down text-uppercase"> Relatórios</span></router-link>
                  </li>
                </ul>

                <ul class="nav navbar-toolbar navbar-right navbar-toolbar-right">
                  <li class="nav-item hidden-xs-down hidden-sm-down hidden-md-down mx-10" id="fullscreen">
                    <a @click="ecraCompleto()" class="nav-link icon icon-fullscreen" href="javascript:void(0)">
                      <span class="sr-only">Fullscreen</span>
                    </a>
                  </li>
                  <li :class="'nav-item dropdown ' + (notificacoes?'show':'')">
                    <a @click="notificacoes=!notificacoes;submenu=false" class="nav-link px-10" href="javascript:void(0)"
                    aria-expanded="false" data-animation="scale-up" role="button">
                      <i class="icon wb-bell"></i>
                      <span v-if="notificacoesTotal" class="badge badge-pill badge-danger up">{{notificacoesTotal}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-media" role="menu" style="overflow: hidden;">
                      <!--<div class="dropdown-menu-header">
                        <h5>{{$t('menu.notificacoes')}}</h5>
                        <span class="badge badge-round badge-danger">{{$t('novas')}} {{notificacoesTotal}}</span>
                      </div>-->
                      <div class="list-group">
                        <div data-role="container">
                          <div data-role="content">
                            <a v-for="(dt,index) in notificacoesRes" :key="index" class="list-group-item dropdown-item" href="javascript:void(0)" role="menuitem">
                              <div class="media">
                                <!--<div class="pr-10">
                                  <i class="icon wb-order bg-red-600 white icon-circle"></i>
                                </div>-->
                                <div class="media-body">
                                  <h6 class="media-heading" :title="dt.text">{{dt.text}}</h6>
                                  <time class="media-meta" datetime="dt.updated_at">{{dt.updated_at}}</time>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item mx-10" id="definicoes">
                    <router-link to="/definicoes/empresa" class="nav-link icon wb-settings" href="javascript:void(0)">
                      <span class="sr-only"></span>
                    </router-link>
                  </li>
                  <li class="nav-item mx-10">
                    <a class="nav-link icon wb-power" href="javascript:void(0)" @click="logout()" >
                      <span class="sr-only">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div v-if="$auth.check() && $store.state.balizas === 'POS' && $store.state.user.email !== 'rececao.smf@balizaslandia.pt' && $store.state.user.email !== 'lanche@balizas.pt' && $store.state.user.email !== 'futebol@balizas.pt' && $store.state.user.email !== 'futebolstaff@balizas.pt'" class="cinzento" style="min-height:100vh;min-width: 100%; padding-top:100px;" id="logoPOSBalizas">{{SET_LOAD()}}
            <router-link style="position: fixed;  right: 0px;top:10px;color:#fff; font-size: 20px;" class="nav-link text-uppercase" to="/frase"><span class="hidden-sm-down hidden-md-down text-uppercase"> Frase</span></router-link>
            <div class="row" v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 meio text-center">
                <a class="mao" @click="selBalizaslandia('smf')">
                  <img :src="$store.state.logoBalizaslandia1" style="max-width:90%;" />
                </a>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 meio text-center">
                <a class="mao" @click="selBalizaslandia('oaz')">
                  <img :src="$store.state.logoBalizaslandia2" style="max-width:90%;" />
                </a>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 meio text-center">
                <a class="mao" @click="$router.push('/balizas');SET_BALIZAS()"><img :src="$store.state.logoBalizas" style="max-width:90%;padding-top:50px;" /></a>
              </div>
            </div>

            <div class="row" v-if="$store.state.user.email.indexOf('.smf@') > 1">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 meio text-center">
                <a class="mao" @click="selBalizaslandia('smf')">
                  <img :src="$store.state.logoBalizaslandia1" style="max-width:100%;padding-top:50px;" />
                </a>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 meio text-center">
                <a class="mao" @click="selBalizaslandia('oaz')">
                  <img :src="$store.state.logoBalizaslandia2" style="max-width:75%;max-width:350px;" />
                </a>
                <br />
                <a class="mao" @click="$router.push('/balizas');SET_BALIZAS()"><img :src="$store.state.logoBalizas" style="max-width:75%;max-width:350px;padding-top:50px" /></a>
              </div>
            </div>

            <div class="row" v-if="$store.state.user.email.indexOf('.oaz@') > 1">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 meio text-center">
                <a class="mao" @click="selBalizaslandia('oaz')">
                  <img :src="$store.state.logoBalizaslandia2" style="max-width:100%;padding-top:50px;" />
                </a>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 meio text-center">
                <a class="mao" @click="selBalizaslandia('smf')">
                  <img :src="$store.state.logoBalizaslandia1" style="max-width:75%;max-width:350px;" />
                </a>
                <br />
                <a class="mao" @click="$router.push('/balizas');SET_BALIZAS()"><img :src="$store.state.logoBalizas" style="max-width:75%;max-width:350px;padding-top:50px" /></a>
              </div>
            </div>
          </div>

          <div v-else class="page pr" :style="$store.state.user.company.id == 5 && !routerFN() || $route.name === 'frase' ? 'margin:0px; min-height: 100vh;' : 'margin: 66px 0px 0px 0px; min-height: calc(100vh - 66px)'" id="page">
            <div class="page-header" v-if="$store.state.title">
              <h1 class="page-title text-uppercase" v-html="$store.state.title"></h1>
            </div>
            <div v-if="$store.state.user">
              <div v-if="$store.state.user.permissions" id="conteudo1" :class="{'page-content p-0': 1 , 'p-10 pb-0 pt-0': $route.name !== 'home' && $route.name !== 'balizaslandia' && $route.name !== 'balizasCriar' && $route.name !== 'balizaslandiacriar' && $route.name !== 'balizas' && $route.name !== 'cacifos' && $route.name !== 'monitor1' && $route.name !== 'balizaslandiaLivre' && $route.name !== 'distribuicao' && $route.name !== 'relatorios'}">
                <transition v-if="$auth.ready()">
                  <router-view></router-view>
                </transition>
              </div>
            </div>
          </div>
        </div>

        <!--<footer class="site-footer m-0" v-if="$route.name !== 'home'">
          <div class="site-footer-legal hidden-sm-down">
            <a v-for="(dt, index) in linguas" :key="index" class="btn btn-icon" :title="dt.lingua" @click="SET_LANGUAGE(dt.sigla)">
              <span :class="'flag-icon ' + dt.icon"></span>
            </a>
          </div>
          <div class="text-center">
              © {{new Date().getFullYear()}} AgendX. <i class="red-600 wb wb-heart"></i> BY <a href="https://epicbit.pt" target="_blank">EpicBit</a>
          </div>
        </footer>-->
      </div>

      <div v-if="!$auth.check() && $auth.ready()" class="animsition page-register-v2 layout-full page-dark">
        <div class="page" data-animsition-in="fade-in" data-animsition-out="fade-out">
          <div class="page-content">
            <div class="page-brand-info">
              <div class="brand">
                <img class="brand-img" :src="$store.state.agendx" alt="Agendx" style="max-width:40%">
              </div>
              <p class="font-size-20" v-html="$t($store.state.texto_logado_false)"></p>
            </div>
            <div class="page-register-main animation-slide-left animation-duration-1">
              <!--v-if="$store.state.user.email.toString().indexOf('@balizas.pt') > 0 || $store.state.user.email.toString().indexOf('.smf@balizaslandia.pt') > 0 || $store.state.user.email.toString() === 'fserra18@gmail.com' || $store.state.user.email.toString() === 'vania_ss@hotmail.com' "-->
              <div class="brand hidden-md-up">
                <img class="brand-img" :src="$store.state.agendx" alt="Agendx" style="max-width:100%">
              </div>

              <transition v-if="$auth.ready()">
                <router-view></router-view>
              </transition>

              <footer class="page-copyright">
                <p>© {{new Date().getFullYear()}} AgendX. <i class="red-600 wb wb-heart"></i> BY <a href="https://epicbit.pt" target="_blank">EpicBit</a></p>
                <div class="social">
                  <a v-for="(dt, index) in bandeiras" :key="index" class="mx-5" href="javascript:void(0)" @click="SET_LANGUAGE(dt.lang)"><img :src="dt.ico" /></a>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/bootstrap-extend.min.css'
import '@/assets/css/site.min.css'
import '@/assets/vendor/chartist-plugin-tooltip/chartist-plugin-tooltip.css'
import '@/assets/css/fonts/web-icons/web-icons.min.css'
import '@/assets/css/register-v2.css'
import '@/assets/css/vue-multiselect.min.css'

import { calendar, deleteCalendar, user, pack, template, notification, meta } from '@/resources/fn'
import { mapMutations } from 'vuex'
import load from './tpl/load'
import rececao from './components/rececao'
import lanche from './components/lanche'
import monitor from './components/monitor'
import cacifo from './components/cacifos'
import monitorConsulta from './components/monitor1'

export default {
  components: { loadLoad: load, lanche, rececao, monitor, cacifo, monitorConsulta },
  data () {
    return {
      mobile: false,
      submenu: false,
      notificacoes: false,
      notificacoesX: false,
      notificacoesTotal: 0,
      notificacoesRes: [],
      minHeight: 0,
      linguas: [
        { lingua: 'English', icon: 'flag-icon-gb', sigla: 'en' },
        { lingua: 'Espanhol', icon: 'flag-icon-es', sigla: 'es' },
        { lingua: 'Português', icon: 'flag-icon-pt', sigla: 'pt' }
      ],
      menu: [
        { title: this.$t('menu.contactos'), icon: 'wb-users', link: '/contactos' },
        { title: this.$t('menu.newsletter'), icon: 'wb-chat-working', link: '/newsletter' }
      ],
      submenu1: [
        { title: this.$t('menu.calendarios'), icon: 'wb-calendar', link: '/calendarios' },
        { title: this.$t('menu.packs'), icon: 'wb-extension', link: '/packs' },
        { title: this.$t('menu.templates'), icon: 'wb-chat-text', link: '/templates' },
        { title: this.$t('menu.historico'), icon: 'wb-inbox', link: '/historico' },
        { title: this.$t('menu.metacontactos'), icon: 'wb-tasks', link: '/meta/contactos' },
        { title: this.$t('menu.metaeventos'), icon: 'wb-tasks', link: '/meta/eventos' }
      ],
      submenu2: [
        { title: this.$t('menu.empresa'), icon: 'wb-info-circle', link: '/definicoes/empresa' },
        { title: this.$t('menu.utilizadores'), icon: 'wb-user-add', link: '/definicoes/utilizadores' },
        { title: this.$t('menu.plano'), icon: 'wb-payment', link: '/definicoes/planos' },
        { title: this.$t('menu.historico_plano'), icon: 'wb-payment', link: '/definicoes/planos-historico' }
      ],
      bandeiras: [
        { lang: 'en', ico: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAQCAMAAADUOCSZAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAF9UExURaN9qYh7rrl8ouWBkUpcrFFhr1VksNOAl3ZwrtF+l414rIV1ro57rN9/ksF8m0dYqbO32fh0fz9RpvRuekZXqexPYOGRo/BYZseXs4mNwe1VZUVZq0NYqu5XZ/Fteml6u05frfaZozxQpvd0f0NUp0NWqGd5u0lbq0daqlBir39xrPWgqXlwrIx1qWdprdl1jcp4lGxqrFtnseZ7jVpiq6BzoPS5wfBmdNbT5vBreF1jq8GAn+5hcNzU5VhmsM92kut1hexRYvRyf5F1p9N1jvaWn996jb53mPNvfLp2mbi93OV7jV9nrlJfrbK22epygtSBmOVzhox8sd1/k6ZynamVvOequN18kZV2qNJ4kfWQm1ZirfCbpsibtnaGwt11io+UxsWXsoeLwfBXZnh3te1UZL+SsbuTs++Pm/Weppl5qLN2m9Glve2Pm+OWp612ncunv+eWpe6kr4Z0q755m7F/pLemxvCird+SpZhzo7ymxsN4lqh9p+CTpOl5imHcJw4AAAAPdFJOU5maIZz8/Pog+Zv5+ZqdIVjzdp0AAAE/SURBVBjTXZEFb8JQFEbvFJgV2GMGRdviOtx9uEpwn7v7fvtKIVu2kyT35Xx5yRVgcdhSDGEYxufyBDwun34hTMrmsACK5bOMEqGfBCGlu14uAiyVAkGPLqtCs4T+oPMEo6VlwER+vSl9oFHLmUSu1iTTpohfhIAg+EJDxWi0ubkKgYJrtUV7FbOQTxAgkUgoqlu9OjlyhgVh5/HlbbVLUbSFnQmDTk12aneEHHatrNYZMA42p4jFYhd+iLvoOjOwzTDCcXxvAl1HUwVbDC/tZkzrDXm1sWb7c6pgl2bYf7qTXfgSgoTvRvb81h9OJCBEiiz1RvzcnKK7Thmu443XjIhECDBSqkumAxGLPEdPmpPP5iYxWBw/5At66+92VK37Qv59vAhzxq9Hs/TvRg0f+701mF9Yaan+XyG7ur7xDZXbQwthp2PMAAAAAElFTkSuQmCC' },
        { lang: 'pt', ico: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAQCAMAAADUOCSZAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABgUExURe9WZVeoZO5VXlioZFeoY+9VZO1TY1WqXlaoYlepYu1VZe5VZleoY+1VZfRRZVCsY5WGZO9WZpWFZFerY+zHVmSvYqu8W/Z5YPVlYvuwWbaeX3qzYMqtXPmgW/rRU7+/WRiqmPgAAAAMdFJOU/qZHpybnJoe/Pn8Huu7C8gAAABXSURBVBjTrdHBDkBAEAPQYlmYxQIA//+XHCWmcTHXl0maFtZkIlVduud5xCGCVt5yW4JUVHGAEHGf0jDpl4nI0O1el/OYiazb+GM29Ye1k8PQRm2kr1BcluYUeLA1g7sAAAAASUVORK5CYII' }
      ],
      apagarCategoriaEvento: '',
      scrollable: false,
      balizas: 0 // homepage apresentar qual a pagina a mostrar
    }
  },
  watch: {
    '$store.state.toast' (val) {
      if (val.msg) {
        this.SET_LOAD()
        this.$swal({
          position: 'top-end',
          type: val.type || 'success',
          title: val.msg,
          showConfirmButton: false,
          timer: 4000,
          toast: true,
          stopKeydownPropagation: false
        }).catch(this.$swal.noop)
      }
    },
    '$store.state.apagarCategoria' () {
      this.apagarCategoriaEvento = ''
    },
    /*
    '$store.state.criarEvento' (val) {
      this.metas = this.$store.state.metaeventos
      this.eventoPasso = 0
      this.contactosPesquisar = []
      this.contactoPesquisar = {}
      this.color = {hex: '#009CE0'}
      if (val.criar === 1) {
        this.addEvent = true
        this.form = {
          calendar_id: this.selectCalendar(),
          repeat: 'no',
          date: this.$store.getters.getDATA,
          time: this.$store.getters.getHORA1,
          client_id: 0,
          name: '',
          phone: '',
          email: '',
          pack_id: '',
          price: '',
          event_at: '',
          observation: '',
          color: '',
          duration_minutes: this.$store.state.user.company.duration_default || 30,
          title: '',
          alerts: []
        }
        this.colorCalendar()
        this.novoContactoModal = false
        this.SET_CRIAREVENTO({})
      } else {
        if (val.criar === 2 && val.data) {
          if (val.data.id) {
            this.addEvent = true
            let dta = val.data.event_at.split(' ')
            this.form = {
              alerts: val.data.alerts,
              client_id: val.data.eventable_id,
              name1: val.data.eventable.name,
              phone1: val.data.eventable.phone,
              email1: val.data.eventable.email,
              calendar_id: val.data.calendar_id,
              repeat: val.data.repeat,
              date: dta[0],
              time: dta[1],
              pack_id: val.data.pack_id,
              price: val.data.price,
              event_at: val.data.event_at,
              observation: val.data.observation,
              color: val.data.color,
              duration_minutes: val.data.duration_minutes,
              title: val.data.title,
              id: val.data.id
            }
            this.color = {hex: val.data.color}
          }
          this.SET_CRIAREVENTO({})
        }
      }
    },
    */
    '$route' () {
      window.scrollTo(0, 0)
      this.SET_LOAD(true)
      this.submenu = false
      this.notificacoes = false
      this.mobile = false
      this.notificacoesX = false
      this.$modal.hide('opcoesApagarCategorias')
      this.$modal.hide('termosCondicoes')
      this.scrollable = false
      this.carregarPedidosIniciais()
      // Math.max(document.body.scrollHeight, document.body.offsetHeight, document.body.getBoundingClientRect().height, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - 110
      if (window.location.pathname !== '/balizaslandia' && window.location.pathname !== '/balizaslandiacriar' && window.location.pathname !== '/balizas' && window.location.pathname !== '/balizascriar' && window.location.pathname !== '/') {
        this.SET_BALIZAS('')
      }
    }
  },
  mounted () {
    if (window.location.pathname !== '/' && this.$auth.check()) {
      this.$router.push('/')
    }
    this.SET_LANGUAGE(this.$localStorage.get('lang'))
    this.carregarPedidosIniciais()
  },
  /*
  updated () {
    this.$auth.ready(function () {
      this.$auth.disableImpersonate()
      this.carregarPedidosIniciais()
      this.minHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.body.getBoundingClientRect().height, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - 110
    })
  },
  */
  created () {
    this.$auth.ready(function () {
      if (this.$auth.check()) {
        this.carregarPedidosIniciais()
        if (!this.$router.history.current.meta.auth) {
          this.$router.replace('/')
        }
      } else {
        // nao tem sessao aberta mas tou numa que tenho que ta logado
        if (this.$router.history.current.meta.auth) {
          this.$router.replace('/login')
        }
      }
    })
  },
  methods: {
    ...mapMutations([
      'SET_TOAST',
      'SET_EVENTS',
      'SET_EVENTS2',
      'SET_USER',
      'SET_USER2',
      'SET_PACKS',
      'SET_PACKS2',
      'SET_TEMPLATE',
      'SET_TEMPLATE2',
      'SET_APAGARCATEGORIA',
      'SET_LOAD',
      'SET_UPDATEROUTERS',
      'SET_TITLE',
      'SET_RESET',
      'SET_METACONTACTOS',
      'SET_METACONTACTOS2',
      'SET_METAEVENTOS',
      'SET_METAEVENTOS2',
      'SET_LANG',
      'SET_BALIZAS',
      'SET_BALIZASLANDIAS'
    ]),
    selBalizaslandia (op) {
      if (this.$store.state.user.email === 'reservas.oaz@balizaslandia.pt' && op === 'smf' || this.$store.state.user.email === 'reservas.smf@balizaslandia.pt' && op === 'oaz') {
        const cod = prompt("Inserir código de mudança de espaço");
        if (cod.toString() === '0000') {
          this.SET_BALIZAS()
          this.SET_BALIZASLANDIAS(op)
          this.$router.push('/balizaslandia')
        } else {
          this.selBalizaslandia(op)
        }
        return
      }
      this.SET_BALIZAS()
      this.SET_BALIZASLANDIAS(op)
      this.$router.push('/balizaslandia')
    },
    routerFN () {
      if (this.$store.state.user.email === 'futebol@balizas.pt' || this.$store.state.user.email === 'futebolstaff@balizas.pt') {
        return 0
      }
      if (window.location.pathname === '/balizaslandia' || window.location.pathname === '/balizaslandiaCriar' || window.location.pathname === '/balizas' || window.location.pathname === '/balizascriar' || window.location.pathname === '/balizaslandiaLivre' || this.$store.state.user.email === 'geral@balizas.pt' || this.$store.state.user.email === 'geral2@balizas.pt' || this.$store.state.user.email === 'reservas.oaz@balizaslandia.pt' || this.$store.state.user.email === 'reservas.smf@balizaslandia.pt') {
        if (window.location.pathname === '/') {
          this.$router.push('/balizas')
        }
        return 0
      }
      return 1
    },
    logoFN () {
      if (this.$store.state.user.email === 'geral@balizas.pt' || this.$store.state.user.email === 'geral2@balizas.pt' || this.$store.state.user.email === 'fserra18@gmail.com' || this.$store.state.user.email === 'vania_ss@hotmail.com' || this.$store.state.user.email === 'reservas.oaz@balizaslandia.pt' || this.$store.state.user.email === 'reservas.smf@balizaslandia.pt' || this.$store.state.user.email === 'landiastaff@balizaslandia.pt') {
        this.SET_BALIZAS('POS')
      } else {
        if (parseInt(this.$store.state.user.company.id) === 5) {
          this.$router.push('/balizas')
        } else {
          this.$router.push('/')
        }
      }
    },
    carregarPedidosIniciais () {
      if (this.$auth.check()) {
        if (!this.$store.state.calendarios2) {
          this.SET_EVENTS2(true)
          calendar(this.axios).then((res) => {
            this.SET_EVENTS(res.data)
          }, () => {})
        }
        if (!this.$store.state.user2) {
          this.SET_USER2(true)
          user(this.axios).then((res) => {
            this.SET_BALIZAS(res.data.email.indexOf('@balizas.pt') > 0 || res.data.email.indexOf('@balizaslandia.pt') > 0 || res.data.email === 'fserra18@gmail.com' || res.data.email === 'vania_ss@hotmail.com' || this.$store.state.user.email === 'landiastaff@balizaslandia.pt' ? 'POS' : '')
            this.SET_USER(res.data)
            if (res.data.ativo < 1) {
              this.logout()
            }
            if (res.data.email === 'futebol@balizas.pt' || res.data.email === 'futebolstaff@balizas.pt') {
              if (!(window.location.pathname === '/balizaslandia' || window.location.pathname === '/balizaslandiaCriar' || window.location.pathname === '/balizas' || this.$route.name === 'balizasCriarID' || this.$route.name === 'balizasCriar' || window.location.pathname === '/balizaslandiaLivre' || this.$route.name === 'contactos' || this.$route.name === 'contactosCriar' || this.$route.name === 'contactosEditar')) {
                this.$router.push('/balizas')
              }
            }
            this.SET_LOAD()
          }, () => {})
        } else {
          if (this.$store.state.user.email === 'futebol@balizas.pt' || this.$store.state.user.email === 'futebolstaff@balizas.pt') {
            if (!(window.location.pathname === '/balizaslandia' || window.location.pathname === '/balizaslandiaCriar' || window.location.pathname === '/balizas' || this.$route.name === 'balizasCriarID' || this.$route.name === 'balizasCriar' || window.location.pathname === '/balizaslandiaLivre' || this.$route.name === 'contactos' || this.$route.name === 'contactosCriar' || this.$route.name === 'contactosEditar')) {
              this.$router.push('/balizas')
            }
          }
          this.SET_LOAD()
        }
        if (!this.$store.state.packs2) {
          this.SET_PACKS2(true)
          pack(this.axios).then((res) => {
            this.SET_PACKS(res.data)
          }, () => {})
        }
        if (!this.$store.state.templates2) {
          this.SET_TEMPLATE2(true)
          template(this.axios).then((res) => {
            this.SET_TEMPLATE(res.data)
          }, () => {})
        }
        if (!this.$store.state.metaContactos2) {
          this.SET_METACONTACTOS2(true)
          meta(this.axios).then((res) => {
            this.SET_METACONTACTOS(this.helpMeta(res.data))
          }, () => {})
        }
        if (!this.$store.state.metaEventos2) {
          this.SET_METAEVENTOS2(true)
          meta(this.axios, 'eventos').then((res) => {
            this.SET_METAEVENTOS(this.helpMeta(res.data))
          }, () => {})
        }
        this.notificacoes1()
      }
    },
    // ordenar e adicionar o option a meta dados
    helpMeta (res) {
      let i = res.length
      while (--i >= 0) {
        if (res[i].meta_key === 'order') {
          continue
        }
        res[i].option = {}
        if (res[i].meta_value.indexOf('|') !== -1) {
          let a = res[i].meta_value.split('|')
          res[i].meta_value = a.shift()
          res[i].option = { total: a.length, valores: a }
        }
      }
      return res
    },
    notificacoes1 () {
      // notificacao vai buscar de 10 em 10m as novas
      if (this.$auth.check()) {
        notification(this.axios).then((res) => {
          this.notificacoesRes = res.data.data
          this.notificacoesTotal = this.notificacoesRes.length
        }, () => {})
        /*
        setTimeout(() => {
          this.notificacoes1()
        }, 600000)
        */
      }
    },
    // Apagar categorias opcoes
    apagarCategoriaModal (op, calendario) {
      this.SET_LOAD(true)
      let a = this.$store.state.apagarCategoria
      let categorias = this.$store.state.calendarios
      deleteCalendar(this.axios, a.id, op, calendario).then(() => {
        categorias.splice(a.index, 1)
        this.SET_EVENTS(categorias)
        this.SET_TITLE(this.$t('categorias.title') + ' (' + this.$store.state.calendarios.length + ')')
        this.SET_TOAST({ msg: this.$t('apagar_sucesso') })
        this.$modal.hide('opcoesApagarCategorias')
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
      this.SET_APAGARCATEGORIA({})
    },
    SET_LANGUAGE (lang) {
      if (!lang) {
        lang = window.navigator.language || window.navigator.browserLanguage
      }
      lang = 'pt'
      if (lang.indexOf('en') > -1) {
        this.SET_LANG('en')
      } else {
        if (lang.indexOf('es') !== -1) {
          this.SET_LANG('es')
        } else {
          this.SET_LANG('pt')
        }
      }
      this.$localStorage.set('lang', lang)
    },
    ecraCompleto () {
      if (window.innerHeight !== screen.height) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          document.documentElement.webkitRequestFullscreen()
        } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
          document.documentElement.msRequestFullscreen()
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    logout () {
      this.$auth.logout({
        makeRequest: false,
        params: {},
        redirect: '/login',
        success: function () {
          this.$auth.token('bearer', '')
          // this.$router.replace('/')
          // window.location.assign('/')
          // window.location = window.location.protocol + '//' + window.location.hostname
        },
        error: function () {
          this.$auth.token('bearer', '')
          // this.$router.replace('/')
        }
      })
    }
  }
}
</script>
