<template>
  <div class="pr">
    <modal name="opcoesEventos" height="auto" :scrollable="scrollable" :adaptive="true" @before-open="scrollable = true" @before-close="scrollable = false" :click-to-close="false">
      <div class="panel m-0" v-if="eventoInfo.id">
        <div class="panel-heading pr" style="background:#65A9E8;color:#fff;">
          <button @click="$modal.hide('opcoesEventos');" class="float-right btn btn-pure btn-md"><i class="icon wb-close"></i></button>
          <!--<router-link :to="'balizascriar/' + eventoInfo.id" v-if="!eventoInfo.newsletter && !eventoInfo.birthday" class="float-right btn btn-pure btn-md texto-branco"><i class="icon wb-pencil"></i></router-link>-->
          <!--v-if="!eventoInfo.finished"-->
          <button @click="$modal.hide('opcoesEventos');apagar(eventoInfo)" class="float-right btn btn-pure btn-md"><i class="icon wb-trash"></i></button>
          <div class="clearfix"></div>
          <h3 :class="{'panel-title text-uppercase texto-branco text-center': 1}" v-if="eventoInfo.title">{{eventoInfo.title}}</h3>
          <router-link :to="'balizascriar/' + eventoInfo.id" v-if="!eventoInfo.newsletter && !eventoInfo.birthday" class="btn btn-pure btn-lg texto-branco" style="position:absolute; bottom:-30px;left:10px;background:#65A9E8;border-radius:100%;width:60px;height:60px;z-index:2;"><i class="icon wb-pencil"></i></router-link>
        </div>
        <div class="panel-body pt-40">
          <p>
            <span>
              <i class="icon wb-time mr-5"></i>
              <span v-if="eventoInfo.event_at.split(' ')[1].substring(4, 5) == 0">{{eventoInfo.event_at.split(' ')[1].substring(0, 2)}}h</span>
              <span v-else>{{eventoInfo.event_at.split(' ')[1].substring(0, 5)}}</span>
            </span>
          </p>
          <p class="description1" v-if="eventoInfo.eventable"><router-link :to="'/contactos/editar/' + eventoInfo.eventable_id"><i class="icon wb-user mr-5"></i> <span class="text-uppercase">{{eventoInfo.eventable.name}}</span></router-link> <i class="icon wb-mobile ml-20 mr-5" v-if="eventoInfo.eventable.phone"></i> {{eventoInfo.eventable.phone}} <i class="icon wb-envelope ml-20 mr-5" v-if="eventoInfo.eventable.email"></i> {{eventoInfo.eventable.email}}</p>
          <p><b>Tipo Jogo:</b> {{tpJogoFN(eventoInfo.tipo_jogo)}}</p>
          <p v-if="eventoInfo.observation"><pre>{{eventoInfo.observation}}</pre></p>

          <div class="form-group col-lg-12">
            <div class="input-group">
              <span class="input-group-addon cinzento texto-branco">Total Numerário</span>
              <input type="text" class="form-control" v-model="form.total1" />
            </div>
          </div>
          <div class="form-group col-lg-12">
            <div class="input-group">
              <span class="input-group-addon cinzento texto-branco">Total Transf/Mbway</span>
              <input type="text" class="form-control" v-model="form.total2" />
            </div>
          </div>
          <div class="form-group col-lg-12">
            <div class="input-group">
              <span class="input-group-addon cinzento texto-branco">Observações Pagamento</span>
              <textarea row="5" style="height:100px;" type="text" class="form-control" v-model="form.obs1"></textarea>
            </div>
          </div>
          <div class="form-group ccol-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="checkbox-custom checkbox-primary">
              <input type="checkbox" id="pp" v-model="form.pp">
              <label for="pp">PP</label>
            </div>
          </div>
          <div class="form-group ccol-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="checkbox-custom checkbox-primary">
              <input type="checkbox" id="pagou123" v-model="form.pagou">
              <label for="pagou123">Pagou adiantado</label>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="clear: both;">
            <a @click="atualizar()" class="float-right btn btn-primary" href="javascript:void(0)">Guardar</a>
            <a @click="$modal.hide('opcoesEventos');" class="float-right btn btn btn-default mr-10" href="javascript:void(0)">Sair</a>
          </div>

          <div class="clearfix"></div>
          <table style="width: 100%;">
            <tr>
              <th>nº</th>
              <th>Jogador</th>
              <th width="60">Valor (€)</th>
              <th>nº</th>
              <th>Jogador</th>
              <th width="60">Valor (€)</th>
            </tr>
            <tr v-for="dt in [1, 3, 5, 7, 9, 11, 13]" :key="dt">
              <td>{{dt}}</td>
              <td><input type="text" class="form-control" v-model="form.n[dt - 1]" /></td>
              <td><input type="text" class="form-control" v-model="form.v[dt - 1]" /></td>
              <td>{{dt + 1}}</td>
              <td><input type="text" class="form-control" v-model="form.n[dt]" /></td>
              <td><input type="text" class="form-control" v-model="form.v[dt]" /></td>
            </tr>
          </table>

          <div style="clear: both;width:100%" v-if="eventoInfo.alerts[0]">{{l('notificacoes')}}
            <ul>
              <li v-for="(dt,index) in eventoInfo.alerts" :key="index" :class="{'riscado': dt.done}"><b>{{$t('alerta.' + dt.alert_type)}}</b> {{$t('avisos.' + dt.when)}} ({{dt.alert_at}})</li>
            </ul>
          </div>
        </div>
      </div>
    </modal>

    <div class="row" style="border-top: solid 1px #B1B1B1;margin:0px;background:#fff;min-height: calc(100vh - 158px)">
      <router-link class="mao hidden-md-up" style="border: solid 1px #fff;border-radius:100%;background:#E46B6D;color:#fff;font-weight:bold;font-size:20px; text-align:center;line-height:40px;width:40px;height:40px;position:fixed;bottom:20px;right:20px;z-index:5;" to="balizascriar">+</router-link>
      <!--desktop-->
      <div style="width:100%;">
        <div class="text-center balizas-calendario cinzento">
          <a @click="logout()" class="mao icon wb-power text-white m-10 float-left"></a>
          <a @click="$store.state.user.email === 'futebol@balizas.pt' || $store.state.user.email === 'futebolstaff@balizas.pt' ? refresh() : SET_BALIZAS('POS')" class="mao">
            <img :src="$store.state.logoBalizas" style="width:95%; margin-top:20px; max-width: 230px;" /></a>

          <div class="p-10 mt-30"><button class="btn btn-success btn-default" @click="onDayClick">Hoje</button></div>
          <v-calendar ref="calendar" :attributes="attributes" @dayclick="onDayClick"></v-calendar>

          <div style="border-top: solid 1px #B1B1B1;padding:14px 5px 0px 5px;margin-top:15px;">
            <input type="text" class="form-control" v-model="nomeEvento" placeholder="PESQUISAR" v-if="calendarioMensal" />

            <button @click="caixaFN()" v-if="diaFN() || $store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'" class="btn btn-block btn-warning mt-10">Contas Fecho</button>
            <button @click="monitorFN()" class="btn btn-block btn-warning mt-10">STAFF</button>

            <div class="p-10 hidden-md-down mt-10" style="background: #ff0; color:#000;'">
              <h3 class="text-center">NOTAS</h3>
              <ol class="p-20">
                <li class="mao text-left" style="border-bottom:solid 1px #fff;" @click="editNota(dt, index)" v-for="(dt, index) in notas" v-show="parseInt(dt.event_at.split(' ')[0].split('-')[2]) == parseInt(day)" :key="index" v-html="(dt.observation || '').toString().replaceAll('\n', '<br />')"></li>
              </ol>
              <div class="form-group" data-plugin="formMaterial" v-if="addNota">
                <textarea rows="5" class="form-control" v-model="nota" />
              </div>
              <button v-if="addNota" @click="addNota = 0; nota = ''; notaID = '';" class="btn btn-default btn-block">Cancelar</button>
              <button v-if="addNota" @click="GuardarNota()" class="btn btn-block btn-primary">Guardar Nota</button>
              <button v-else @click="addNota = 1; nota = ''; notaID = '';" class="btn btn-block btn-warning">Adicionar Nota</button>
            </div>

            <button @click="$router.push('contactos')" class="btn btn-block btn-default mt-50">Contactos</button>
          </div>

          <div style="padding:14px 5px 0px 5px;" v-if="$store.state.user.company.id != 5">
            <h5 class="text-uppercase">{{$t('home.Meus_calendarios')}}</h5>
            <div :class="'checkbox-custom checkbox-' + dt.color.substring(1)" :key="index" v-for="(dt, index) in $store.state.calendarios" v-show="dt.active">
              <input type="checkbox" :id="'calendario' + index" v-model="categoria" :value="dt.id">
              <label :for="'calendario' + index"> {{dt.name}}</label>
            </div>
          </div>
        </div>
        <div class="balizas-tabela cinzento1" v-if="caixa || monitor">
          <h3 class="ml-20 mt-0 pt-20" v-if="caixa">Contas Fecho<button @click="caixaFN()" class="btn btn-danger float-right">Sair</button></h3>
          <div class="cinzento1" v-if="caixa && (diaFN() || $store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt')">
            <table class="table balizas mb-20" style="width:auto;margin: 0 auto;" v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com' || $store.state.user.email === 'landiastaff@balizaslandia.pt'">
              <thead>
                <tr>
                  <th class="meio  azul text-center">Descrição</th>
                  <th class="meio  azul text-center">Quant.</th>
                  <th class="meio  azul text-center">Numerário €</th>
                  <th class="meio  azul text-center">Transf/Mbway €</th>
                </tr>
              </thead>
              <tbody style="color:#000;">
                <tr>
                  <th class="meio text-left">Jogo Fixo 42€</th>
                  <td class="meio text-right">{{relatorios.q1}}</td>
                  <td class="meio text-right">{{relatorios.n1}} €</td>
                  <td class="meio text-right">{{relatorios.t1}} €</td>
                </tr>
                <tr>
                  <th class="meio text-left">Jogo Normal 48€</th>
                  <td class="meio text-right">{{relatorios.q2}}</td>
                  <td class="meio text-right">{{relatorios.n2}} €</td>
                  <td class="meio text-right">{{relatorios.t2}} €</td>
                </tr>
                <tr>
                  <th class="meio text-left">Jogo Fixo LowCost 30€</th>
                  <td class="meio text-right">{{relatorios.q3}}</td>
                  <td class="meio text-right">{{relatorios.n3}} €</td>
                  <td class="meio text-right">{{relatorios.t3}} €</td>
                </tr>
                <tr>
                  <th class="meio text-left">Jogo Normal LowCost 35€</th>
                  <td class="meio text-right">{{relatorios.q4}}</td>
                  <td class="meio text-right">{{relatorios.n4}} €</td>
                  <td class="meio text-right">{{relatorios.t4}} €</td>
                </tr>
                <tr>
                  <th class="meio text-left">Festa BalizasLandia</th>
                  <td class="meio text-right">{{relatorios.q5}}</td>
                  <td class="meio text-right">{{relatorios.n5}} €</td>
                  <td class="meio text-right">{{relatorios.t5}} €</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="cinzento text-right meio">Total Jogos</th>
                  <th class="cinzento meio text-right">{{parseFloat(relatorios.q1 || 0) + parseFloat(relatorios.q2 || 0) + parseFloat(relatorios.q3 || 0) + parseFloat(relatorios.q4 || 0) + parseFloat(relatorios.q5 || 0)}}</th>
                  <th class="cinzento meio text-right">{{parseFloat(relatorios.n1 || 0) + parseFloat(relatorios.n2 || 0) + parseFloat(relatorios.n3 || 0) + parseFloat(relatorios.n4 || 0) + parseFloat(relatorios.n5 || 0)}} €</th>
                  <th class="cinzento meio text-right">{{parseFloat(relatorios.t1 || 0) + parseFloat(relatorios.t2 || 0) + parseFloat(relatorios.t3 || 0) + parseFloat(relatorios.t4 || 0) + parseFloat(relatorios.t5 || 0)}} €</th>
                </tr>

                <tr>
                  <th class="cinzento text-right meio">Impressão do Bill</th>
                  <th class="cinzento meio text-right">--</th>
                  <th class="cinzento meio text-right">{{parseFloat(caixa1.num2 || 0)}} €</th>
                  <th class="cinzento meio text-right">{{parseFloat(caixa1.trans2 || 0)}} €</th>
                </tr>

                <tr>
                  <th class="text-right meio">Total</th>
                  <th class="meio text-right">{{parseFloat(relatorios.q1 || 0) + parseFloat(relatorios.q2 || 0) + parseFloat(relatorios.q3 || 0) + parseFloat(relatorios.q4 || 0) + parseFloat(relatorios.q5 || 0)}}</th>
                  <th class="meio text-right">{{parseFloat(relatorios.n1 || 0) + parseFloat(relatorios.n2 || 0) + parseFloat(relatorios.n3 || 0) + parseFloat(relatorios.n4 || 0) + parseFloat(relatorios.n5 || 0) + parseFloat(caixa1.num2 || 0)}} €</th>
                  <th class="meio text-right">{{parseFloat(relatorios.t1 || 0) + parseFloat(relatorios.t2 || 0) + parseFloat(relatorios.t3 || 0) + parseFloat(relatorios.t4 || 0) + parseFloat(caixa1.trans2 || 0)}} €</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <th class="meio text-right">{{parseFloat(relatorios.n1 || 0) + parseFloat(relatorios.n2 || 0) + parseFloat(relatorios.n3 || 0) + parseFloat(relatorios.n4 || 0)  + parseFloat(relatorios.n5 || 0) + parseFloat(caixa1.num2 || 0) + 70}} €</th>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <table class="table balizas mb-20" style="width:auto;margin: 0 auto;">
              <thead>
                <tr>
                  <th class="meio  azul text-center">Descrição</th>
                  <th class="meio  azul text-center">Numerário €</th>
                  <th class="meio  azul text-center">Transf/Mbway €</th>
                  <th class="meio  azul text-center">Observações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="text-right meio">Fecho 1</th>
                  <td>
                    <input v-if="caixa1.fecho1" type="text" class="form-control" v-model="caixa1.num1" placeholder="Valor" disabled />
                    <input v-else type="text" class="form-control" v-model="caixa1.num1" placeholder="Valor" />
                  </td>
                  <td>
                    <input v-if="caixa1.fecho1" type="text" class="form-control" v-model="caixa1.trans1" placeholder="Valor" disabled />
                    <input v-else type="text" class="form-control" v-model="caixa1.trans1" placeholder="Valor" />
                  </td>
                  <textarea row="3" type="text" class="foco form-control" placeholder="Observações" v-model="caixa1.obs1"></textarea>
                </tr>
                <tr>
                  <th class="text-right meio">Impressão do Bill</th>
                  <td>
                    <input v-if="caixa1.fecho1" type="text" class="form-control" v-model="caixa1.num2" placeholder="Valor" disabled />
                    <input v-else type="text" class="form-control" v-model="caixa1.num2" placeholder="Valor" />
                  </td>
                  <td>
                    <input v-if="caixa1.fecho1" type="text" class="form-control" v-model="caixa1.trans2" placeholder="Valor" disabled />
                    <input v-else type="text" class="form-control" v-model="caixa1.trans2" placeholder="Valor" />
                  </td>
                  <textarea row="3" type="text" class="foco form-control" placeholder="Observações" v-model="caixa1.obs2"></textarea>
                </tr>
              </tbody>
              <tfoot>
                <!--<tr>
                  <th class="text-right meio">Total</th>
                  <th class="meio text-right">{{parseFloat(caixa1.num1 || 0) + parseFloat(caixa1.num2 || 0) + parseFloat(caixa1.num3 || 0)}} €</th>
                  <th class="meio text-right">{{parseFloat(caixa1.trans1 || 0) + parseFloat(caixa1.trans2 || 0) + parseFloat(caixa1.trans3 || 0)}} €</th>
                  <th></th>
                </tr>-->
                <tr>
                  <th class="text-right meio">Fecho</th>
                  <th></th>
                  <th>
                    <input v-if="caixa1.fecho1" type="text" class="form-control" v-model="caixa1.nome" placeholder="Nome" disabled />
                    <input v-else type="text" class="form-control" v-model="caixa1.nome" placeholder="Nome" />
                  </th>
                  <th>
                    <a @click="caixaAtualizar()" class="btn btn-primary float-right btn-block" href="javascript:void(0)">Guardar</a>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>

          <h3 class="ml-20" v-if="monitor">STAFF<button @click="monitorFN()" class="btn btn-danger float-right">Sair</button></h3>
          <div class="cinzento1" v-if="monitor">
            <table class="table balizas mb-20" style="width:auto;margin: 0 auto;">
              <thead>
                <tr>
                  <th class="meio  azul text-center">Monitor</th>
                  <th class="meio  azul text-center">Hora Entrada</th>
                  <th class="meio  azul text-center">Hora Saída</th>
                </tr>
              </thead>
              <tbody style="color:#000;">
                <tr v-for="(dt, index) in monitores" :key="index">
                  <td class="meio text-left">{{dt.eventable.name}}</td>
                  <td class="meio text-center">{{meta(dt, 'Anos 2')}}</td>
                  <td class="meio text-center">{{meta(dt, 'Anos 3')}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="text-right meio">
                    <select  class="form-control" v-model="monitor1.user">
                      <option value=""></option>
                      <option v-for="(dt, index) in users" :key="index" :value="dt.id">{{dt.name}}</option>
                    </select>
                  </th>
                  <th><input type="time" style="margin:1px;" v-model="monitor1.entrada" /></th>
                  <th><input type="time" style="margin:1px;" v-model="monitor1.saida" /></th>
                </tr>
                <tr>
                  <th class="meio" colspan="100%">
                    <a @click="monitorAdd" class="btn btn-primary float-right btn-block" href="javascript:void(0)">Guardar</a>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="balizas-tabela cinzento1" v-if="calendarioMensal">
            <h3 class="ml-20 pt-20 m-0">Marcação de Jogos
              <router-link class="btn btn-primary ml-20 pl-40 pr-40 cinzento" :to="'/balizascriar?d=' + year + '-' + month + '-' + day"><b>Marcar Reserva</b></router-link>
              <button @click="upCalendarioMensal()" class="btn btn-default float-right mr-10" style="background:#fff;border: solid 1px #000; color:#000;"><i class="icon wb-calendar"></i></button>
            </h3>
            <span style="font-size: 21px" class="ml-20 m-9 ">{{parseInt(day)}} de {{$t('meses.' + parseInt(month))}} de {{year}}</span>
            <div>
              <table class="table" style="width:100%;">
                <tbody class="tbodyMes">
                  <!--current-day-->
                  <tr v-if="semana0[6] < 8">
                    <td :key="index" v-for="(dt,index) in semana0" :class="{'current-day': dt<15 && dt==day, 'prev-month': !(dt<21)}" >
                      <span v-if="dt<21">
                        <span class="float-left">{{$t('semana.' + index)}}</span>
                        <br />
                        <b v-if="day > dt && dias[dt] && !semana0X[index] && !nomeEvento" class="mao" @click="$set(semana0X, index, !semana0X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                        <b v-else-if="day > dt && dias[dt] && semana0X[index] && !nomeEvento" class="mao" @click="$set(semana0X, index, !semana0X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                        <b v-else>{{dt}}</b>

                        <div :class="{eventos: 1, jogobalizaslandia: dtt.tipo_jogo == 5 }" :key="ind" @click="openModalFN(dtt, ind)" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dt<15 && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana0X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                          <span class="mao">
                            <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                            <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                            <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                            <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt, 'pago': meta(dtt, 'Total1') > 0 || meta(dtt, 'Total2') > 0 || meta(dtt, 'pagou')}" v-if="dtt.title">{{dtt.title}}</span>
                            <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>{{l('aniversario')}}</span>
                          </span>
                        </div>
                      </span>
                      <span v-else>
                        <a @click="selectDay(dt, 0)" href="javascript:void(0)"><span class="float-left">{{$t('semana.' + index)}}</span><br /><b>{{dt}}</b></a>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td :key="index" v-for="(dt,index) in semana1" :class="{'current-day': dt==day}">
                      <span class="float-left" v-if="!(semana0[6] < 8)">{{$t('semana.' + index)}}</span>
                      <br />
                      <b v-if="day > dt && dias[dt] && !semana1X[index] && !nomeEvento" class="mao" @click="$set(semana1X, index, !semana1X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                      <b v-else-if="day > dt && dias[dt] && semana1X[index] && !nomeEvento" class="mao" @click="$set(semana1X, index, !semana1X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                      <b v-else>{{dt}}</b>

                      <div :class="{eventos: 1, jogobalizaslandia: dtt.tipo_jogo == 5 }" :key="ind" @click="openModalFN(dtt, ind)" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana1X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                        <span class="mao">
                          <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                          <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                          <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt, 'pago': meta(dtt, 'Total1') > 0 || meta(dtt, 'Total2') > 0 || meta(dtt, 'pagou')}" v-if="dtt.title">{{dtt.title}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>{{l('aniversario')}}</span>
                        </span>
                      </div>

                    </td>
                  </tr>
                  <tr>
                    <td :key="index" v-for="(dt,index) in semana2" :class="{'current-day': dt==day}">
                      <b v-if="day > dt && dias[dt] && !semana2X[index] && !nomeEvento" class="mao" @click="$set(semana2X, index, !semana2X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                      <b v-else-if="day > dt && dias[dt] && semana2X[index] && !nomeEvento" class="mao" @click="$set(semana2X, index, !semana2X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                      <b v-else>{{dt}}</b>

                      <div :class="{eventos: 1, jogobalizaslandia: dtt.tipo_jogo == 5 }" :key="ind" @click="openModalFN(dtt, ind)" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana2X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                        <span class="mao">
                          <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                          <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                          <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt, 'pago': meta(dtt, 'Total1') > 0 || meta(dtt, 'Total2') > 0 || meta(dtt, 'pagou')}" v-if="dtt.title">{{dtt.title}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>l('aniversario')}}</span>
                        </span>
                      </div>

                    </td>
                  </tr>
                  <tr>
                    <td :key="index" v-for="(dt,index) in semana3" :class="{'current-day': dt==day}">
                      <b v-if="day > dt && dias[dt] && !semana3X[index] && !nomeEvento" class="mao" @click="$set(semana3X, index, !semana3X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                      <b v-else-if="day > dt && dias[dt] && semana3X[index] && !nomeEvento" class="mao" @click="$set(semana3X, index, !semana3X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                      <b v-else>{{dt}}</b>

                      <div :class="{eventos: 1, jogobalizaslandia: dtt.tipo_jogo == 5 }" :key="ind" @click="openModalFN(dtt, ind)" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana3X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                        <span class="mao">
                          <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                          <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                          <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt, 'pago': meta(dtt, 'Total1') > 0 || meta(dtt, 'Total2') > 0 || meta(dtt, 'pagou')}" v-if="dtt.title">{{dtt.title}}</span>
                          <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>l('aniversario')}}</span>
                        </span>
                      </div>

                    </td>
                  </tr>
                  <tr>
                    <td :key="index" v-for="(dt,index) in semana4" :class="{'current-day': dt==day, 'next-month': !(dt>14)}">
                      <div v-if="dt>14">
                        <b v-if="day > dt && dias[dt] && !semana4X[index] && !nomeEvento" class="mao" @click="$set(semana4X, index, !semana4X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                        <b v-else-if="day > dt && dias[dt] && semana4X[index] && !nomeEvento" class="mao" @click="$set(semana4X, index, !semana4X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                        <b v-else>{{dt}}</b>

                        <div :class="{eventos: 1, jogobalizaslandia: dtt.tipo_jogo == 5 }" :key="ind" @click="openModalFN(dtt, ind)" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dt>20 && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana4X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                          <span class="mao">
                            <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                            <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                            <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                            <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt, 'pago': meta(dtt, 'Total1') > 0 || meta(dtt, 'Total2') > 0 || meta(dtt, 'pagou')}" v-if="dtt.title">{{dtt.title}}</span>
                            <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>l('aniversario')}}</span>
                          </span>
                        </div>
                      </div>
                      <div v-else>
                        <a @click="selectDay(dt, 4)" href="javascript:void(0)"><b>{{dt}}</b></a>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="semana5[0] > 17">
                    <td :key="index" v-for="(dt,index) in semana5" :class="{'current-day': dt==day, 'next-month': !(dt>14)}">
                      <span v-if="dt>14">
                        <b v-if="day > dt && dias[dt] && !semana5X[index] && !nomeEvento" class="mao" @click="$set(semana5X, index, !semana5X[index])">{{dt}} <i class="wb-chevron-down-mini"></i></b>
                        <b v-else-if="day > dt && dias[dt] && semana5X[index] && !nomeEvento" class="mao" @click="$set(semana5X, index, !semana5X[index])">{{dt}} <i class="wb-chevron-up-mini"></i></b>
                        <b v-else>{{dt}}</b>

                        <div :key="ind" @click="openModalFN(dtt, ind)" v-for="(dtt, ind) in res" v-show="(dt == dtt.event_at.substring(8, 10) && dt>20 && dias[dt] && categoria.find(a => a === dtt.calendar_id) && (day <= dt || semana5X[index])) && (dtt.title.toLowerCase().indexOf(nomeEvento.toLowerCase()) > -1 || !nomeEvento)">
                          <span class="mao">
                            <div class="calendarCorBorda" :style="'border-color:' + dtt.color + ' !important'"></div>
                            <span class="hora" v-if="dtt.event_at.split(' ')[1].substring(3, 5) == 0">{{dtt.event_at.split(' ')[1].substring(0, 2)}}h</span>
                            <span class="hora" v-else>{{dtt.event_at.split(' ')[1].substring(0, 5)}}</span>
                            <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt, 'pago': meta(dtt, 'Total1') > 0 || meta(dtt, 'Total2') > 0 || meta(dtt, 'pagou')}" v-if="dtt.title">{{dtt.title}}</span>
                            <span :class="{'text-right': 1, 'riscado': dtt.finished && day == dt}" v-else>l('aniversario')}}</span>
                          </span>
                        </div>
                      </span>
                      <span v-else>
                        <a @click="selectDay(dt, 5)" href="javascript:void(0)"><b>{{dt}}</b></a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="balizas-tabela cinzento1" v-else>
            <h3 class="ml-20 pt-20 m-0">Marcação de Jogos
              <router-link class="btn btn-primary ml-20 pl-40 pr-40 cinzento" :to="'/balizascriar?d=' + year + '-' + month + '-' + day"><b>Marcar Reserva</b></router-link>
              <button @click="upCalendarioMensal()" class="btn btn-default float-right mr-10" style="background:#fff;border: solid 1px #000; color:#000;"><i class="icon wb-calendar"></i></button>
            </h3>
            <span style="font-size: 21px" class="ml-20 m-9 ">{{parseInt(day)}} de {{$t('meses.' + parseInt(month))}} de {{year}}</span>
            <div>
              <table class="table table-condensed" style="width:100%;">
                <tbody class="tbodyMes">
                  <tr>
                    <td>
                      <table class="table mb-0">
                        <tr :key="ind" v-for="(dtt, ind) in res" v-show="day == dtt.day && categoria.find(a => a === dtt.calendar_id)" @click="openModalFN(dtt, ind)" class="mao pr">
                          <td style="width:64px;" class="px-0">
                            <div class="calendarCorBorda" :style="'border-color:' + dtt.color + '!important'"></div>
                            <span class="hora mx-10">{{dtt.hora}}</span>
                          </td>
                          <td>
                            {{tpJogoFN(dtt.tipo_jogo)}}
                          </td>
                          <td v-if="dtt.eventable">
                            <i class="icon wb-user mr-5"></i> <a :class="{'pago': meta(dtt, 'Total1') > 0 || meta(dtt, 'Total2') > 0 || meta(dtt, 'pagou')}">{{dtt.eventable.name}}</a>
                          </td>
                          <td v-if="dtt.eventable">
                            <span v-if="dtt.eventable.phone"><i class="icon wb-mobile mr-5"></i> {{dtt.eventable.phone}}</span>
                            <span v-else><i class="icon wb-envelope mr-5" ></i> {{dtt.eventable.email}}</span>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <div v-if="!dias[parseInt(day)] && !finalizados" class="mr-20 ml-20 alert dark alert-danger my-50">{{l('nao_tem_eventos_neste_dia')}}</div>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <div v-if="!monitor && !caixa" class="p-10 hidden-md-up" :style="'background:' + (notas[0] ? '#FFFF00' : '#ccc') + '; color:#000;'">
                <h3 class="text-center">NOTAS</h3>
                <ol class="p-20">
                  <li class="mao text-left" style="border-bottom:solid 1px #fff;" v-show="parseInt(dt.event_at.split(' ')[0].split('-')[2]) == parseInt(day)" @click="editNota(dt, index)" v-for="(dt, index) in notas" :key="index">{{dt.observation}}</li>
                </ol>
                <div class="form-group" data-plugin="formMaterial" v-if="addNota">
                  <textarea rows="5" class="form-control" v-model="nota" />
                </div>
                <button v-if="addNota" @click="addNota = 0; nota = ''; notaID = '';" class="btn btn-default btn-block">Cancelar</button>
                <button v-if="addNota" @click="GuardarNota()" class="btn btn-block btn-primary">Guardar Nota</button>
                <button v-else @click="addNota = 1; nota = ''; notaID = '';" class="btn btn-block btn-warning">Adicionar Nota</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--mobile-->
      <!--<div class="container-fluid hidden-md-up p-0 m-0 pr pb-60">
        <table style="width:85%;margin: 0 auto;">
          <thead>
            <tr>
              <td class="text-center">{{$t('semanaS.0')}}</td>
              <td class="text-center">{{$t('semanaS.1')}}</td>
              <td class="text-center">{{$t('semanaS.2')}}</td>
              <td class="text-center">{{$t('semanaS.3')}}</td>
              <td class="text-center">{{$t('semanaS.4')}}</td>
              <td class="text-center">{{$t('semanaS.5')}}</td>
              <td class="text-center">{{$t('semanaS.6')}}</td>
            </tr>
          </thead>
          <tbody id="calendarioNormal">
            <tr v-if="semana0[6] < 8">
              <td @click="dt<21 ? selectDayCalendar(dt) : selectDay(dt, 0)" :key="index" v-for="(dt,index) in semana0" :class="{'text-center mao': 1, 'prev-month': !(dt<21)}">
                <b :class="{'current-day': dt<15 && dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr>
                <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana1" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr>
                <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana2" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr>
                <td @click="selectDayCalendar(dt)" :key="index" v-for="(dt,index) in semana3" class="text-center mao"><b :class="{'current-day': dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr>
                <td @click="dt>14 ? selectDayCalendar(dt) : selectDay(dt, 4)" :key="index" v-for="(dt,index) in semana4" :class="{'text-center mao': 1, 'next-month': !(dt>14)}"><b :class="{'current-day': dt>20 && dt==day}">{{dt}}</b>
                </td>
              </tr>
              <tr v-if="semana5[0] > 17">
                <td @click="dt>14 ? selectDayCalendar(dt) : selectDay(dt, 5)" :key="index" v-for="(dt,index) in semana5" :class="{'text-center mao': 1, 'next-month': !(dt>14)}"><b :class="{'current-day': dt>20 && dt==day}">{{dt}}</b>
                </td>
              </tr>
            </tbody>
          </table>

        <div>
          <select class="selectCalendar diaX" v-model="day" @change="calendarioMes()">
            <option v-show="new Date(year, month, 0 ).getDate() >= n" v-for="n in 31" :key="n" :value="(n<10?'0':'')+n">{{n}}</option>
          </select>

          <select class="selectCalendar mesX" v-model="month" @change="calendarioMes(1)">
            <option v-for="n in 12" :key="n" :value="(n<10?'0':'')+n">{{$t('meses.' + n)}}</option>
          </select>

          <select class="selectCalendar anoX" v-model="year" @change="calendarioMes()">
            <option v-for="n in anos" :key="n" :value="n">{{n}}</option>
          </select>
        </div>

        <div class="clearfix"></div>
        <div v-if="res[0]" class="event-calendar1 pt-15 mb-15">
          <div class="event-list1">
            <div class="event1" v-for="(dt, index) in res" :key="index">
              <div class="event-container1 pr" :style="'border-bottom-color:' + dt.color" v-if="parseInt(day) == parseInt(dt.event_at.substring(8, 10)) && (!categoria || categoria.find(a => a === dt.calendar_id))">
                <div class="duracao" v-if="dt.duration_minutes>0"><span class="icon wb-time"></span> {{dt.duration_minutes}}m</div>
                <span class="date-container1">
                  <span class="date1">{{dt.event_at.split(' ')[1].substring(0, 5)}}
                    <span class="month1" v-if="0">
                      <span class="float-right">{{dt.event_at.split(' ')[0].split('-')[2]}}/{{dt.event_at.split('-')[1]}}</span>
                    </span>
                  </span>
                </span>
                <span class="detail-container1">
                  <span :class="'title1 ' + (dt.finished?'finished':'')">{{dt.title?dt.title:l('aniversario')}}</span>
                </span>
                <div class="openEv1">
                  <span class="description1" v-if="dt.eventable">{{dt.eventable.name}}<br />{{dt.eventable.phone}} <span v-show="dt.eventable.email">- {{dt.eventable.email}}</span></span>
                  <p v-if="dt.calendar"><span class="icon wb-calendar"></span> {{dt.calendar.name}}</p>
                  <p v-if="(dt.pack_id || dt.price)">
                    <span class="icon wb-extension"></span>
                    <span v-if="dt.pack_id">{{$store.state.packs.find(a => a.id === dt.pack_id).name}}</span>
                    <span v-if="dt.pack_id && dt.price">*</span>
                    <span v-if="dt.price">{{dt.price}}</span>
                    <b v-if="dt.pack_id && dt.price"> = {{$store.state.packs.find(a => a.id === dt.pack_id).size * dt.price}} €</b>
                  </p>
                  <p v-if="dt.observation">{{dt.observation}}</p>
                  <div class="alertopcoes">
                    <router-link :to="'balizascriar/' + dt.id" class="icon wb-pencil float-right"></router-link>
                    <a v-if="!dt.finished" @click="apagar(dt, index)" class="icon wb-trash ml-20 float-right" href="javascript:void(0)"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="finalizados && dias[parseInt(day)]" class="mr-20 ml-20 alert dark alert-danger my-50">{{l('so_tem_eventos_finalizados')}}</div>
          <div v-if="!dias[parseInt(day)] && !finalizados" class="mr-20 ml-20 alert dark alert-danger my-50">{{l('nao_tem_eventos_neste_dia')}}</div>
        </div>

        <div v-else class="mr-20 ml-20 alert dark alert-danger my-50">{{l('nao_tem_eventos_este_mes')}}</div>
      </div>-->

      <!--<select v-if="$store.state.calendarios.length > 1 && $store.state.user.company.id != 5" v-model="categoria" style="position:fixed;bottom:21px;left:20px;width:65%;" class="hidden-md-up form-control">
        <option :value="$store.state.calendarios.map(a => a.active ? a.id : '')">Categorias</option>
        <option :value="[dt.id]" :key="index" v-for="(dt, index) in $store.state.calendarios" v-show="dt.active">{{dt.name}}</option>
      </select>-->
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { deleteEvent, searchEvent, searchClient, createEventClient, patchEventClient, empresaUsers } from '@/resources/fn'
import _ from 'lodash'

export default {
  name: 'pageBalizas',
  data () {
    return {
      res: [],
      categoria: [],
      year: '',
      month: '',
      cor: '',
      before: '',
      after: '',
      day: '',
      finished: '',
      finished1: false,
      anos: [],
      semana0: [],
      semana1: [],
      semana2: [],
      semana3: [],
      semana4: [],
      semana5: [],
      semana0X: [],
      semana1X: [],
      semana2X: [],
      semana3X: [],
      semana4X: [],
      semana5X: [],
      dias: [],
      mesAntes: '',
      anoAntes: '',
      finalizados: false,
      calendarioMensal: false,
      eventoInfo: {},
      eventoInfoIndex: '',
      scrollable: false,
      ano: new Date().getFullYear() + 1,
      dataMin: '2017-01-01',
      exportar: {},
      isLoadingcontacto: false,
      contactosPesquisar: [],
      nomeEvento: '',
      attributes: [{
        dates: new Date(),
        key: 'today',
        highlight: true
      }],
      caixa: false,
      caixa1: {
        num1: '',
        trans1: '',
        obs1: '',
        num2: '',
        trans2: '',
        obs2: '',
        num3: '',
        trans3: '',
        obs3: '',
        fecho1: 0
      },
      monitor: false,
      monitor1: {
        entrada: '',
        saida: '',
        user: ''
      },
      monitores: [],
      relatorios: {
        q1: '',
        n1: '',
        t1: '',
        q2: '',
        n2: '',
        t2: '',
        q3: '',
        n3: '',
        t3: '',
        q4: '',
        n4: '',
        t4: '',
        q5: '',
        n5: '',
        t5: ''
      },
      form: {
        pagou: false,
        pp: false,
        total1: '',
        total2: '',
        obs1: ''
      },
      users: [],
      user: '',
      nota: '',
      addNota: 0,
      notas: [],
      notaID: ''
    }
  },
  mounted () {
    this.SET_TITLE('')
    let xxx = decodeURIComponent(window.location.search).substring(3)
    if (xxx) {
      this.onDayClick({ id: xxx })
    } else {
      let a = new Date()
      this.onDayClick({ id: a.getFullYear() + '-' + (1 + a.getMonth()) + '-' + a.getDate() })
    }
    this.init()
    if (window.orientation > -1) {
      this.calendarioMensal = 0
    }
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_DATA',
      'SET_BALIZAS',
      'SET_TITLE'
    ]),
    l (a) {
      return this.$t('home.' + a)
    },
    refresh () {
      this.caixa = 0
      this.monitor = 0
      this.SET_BALIZAS()
      this.SET_TITLE('')
      let xxx = decodeURIComponent(window.location.search).substring(3)
      if (xxx) {
        this.onDayClick({ id: xxx })
      } else {
        let a = new Date()
        this.onDayClick({ id: a.getFullYear() + '-' + (1 + a.getMonth()) + '-' + a.getDate() })
      }
      this.init()
      if (window.orientation > -1) {
        this.calendarioMensal = 0
      }
    },
    checkMeta (dt) {
      let a = this.$store.state.metaeventos.find(a => a.meta_key === dt.meta_key)
      if (a) {
        if (a.meta_value === 'image') {
          return true
        }
      }
      return false
    },
    meta (dt, campo, campo1, virgula) {
      if (!dt) {
        return ''
      }
      if (dt.id) {
        let a = dt.meta.find(a => a.meta_key === campo)
        if (a) {
          if (!a.meta_value) {
            return ''
          }
          if ((a.meta_value === 'true' || a.meta_value === '1' || a.meta_value === 1) && a.meta_key !== 'Caução Paga' && a.meta_key !== 'validado' && a.meta_key !== 'Sitio' && a.meta_key !== 'tempo' && a.meta_key !== 'Total1' && a.meta_key !== 'Total2' && a.meta_key !== 'Vales' && a.meta_key !== 'valor recebido' && a.meta_key !== 'Total Crianças' && a.meta_key !== 'Anos' && a.meta_key !== 'Anos 2' && a.meta_key !== 'Anos 3' && a.meta_key !== 'Anos 4' && a.meta_key !== 'Meias' && a.meta_key !== 'bolo_qtd' && a.meta_key !== 'Adulto' && a.meta_key !== 'menu') {
            if (campo1) {
              let b = dt.meta.find(x => x.meta_key === campo1)
              if (b) {
                if (b.meta_value) {
                  return a.meta_key + ' -> ' + b.meta_value + (virgula && a.meta_value ? ' / ' : '')
                } else {
                  return a.meta_key
                }
              }
            }
            if (virgula === 3) {
              return a.meta_key + (virgula && a.meta_key ? ' -- ' : '')
            }
            if (virgula === 2) {
              return a.meta_key + (virgula && a.meta_key ? ' / ' : '')
            }
            return a.meta_key + (virgula && a.meta_key ? ' ' : '')
          } else {
            if (a.meta_value === '0' || a.meta_value === 0) {
              return ''
            }
            return a.meta_value + (virgula && a.meta_value ? ' / ' : '')
          }
        }
      }
      return ''
    },
    reset () {
      this.semana0X = [0, 0, 0, 0, 0, 0, 0]
      this.semana1X = [0, 0, 0, 0, 0, 0, 0]
      this.semana2X = [0, 0, 0, 0, 0, 0, 0]
      this.semana3X = [0, 0, 0, 0, 0, 0, 0]
      this.semana4X = [0, 0, 0, 0, 0, 0, 0]
      this.semana5X = [0, 0, 0, 0, 0, 0, 0]
    },
    /*
    verMais (i, i1) {
      switch (i) {
        case 0:
          this.$set(this.semana0X, i1, true)
          break
        case 1:
          ++this.semana1X[i1]
          break
        case 2:
          ++this.semana2X[i1]
          break
        case 3:
          ++this.semana3X[i1]
          break
        case 4:
          ++this.semana4X[i1]
          break
        case 5:
          ++this.semana5X[i1]
          break
        case 6:
          ++this.semana6X[i1]
          break
      }
    },
    */
    /*
      thisMonth (d, h, m) {
        const t = new Date()
        return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
      },
      onClickDay (d) {
        this.message = `You clicked: ${d.toLocaleDateString()}`
      },
      onClickEvent (e) {
        this.message = `You clicked: ${e.title}`
      },
      setShowDate (d) {
        this.message = `Changing calendar view to ${d.toLocaleDateString()}`
        this.showDate = d
      },
      clickTestAddEvent () {
        if (this.alreadyAdded) {
          return
        }
        this.alreadyAdded = true
        this.events.push({
          id: 'e12',
          startDate: this.thisMonth(22),
          endDate: this.thisMonth(23),
          title: 'New Event'
        })
      },
    */
    init () {
      if (this.$store.state.calendarios1 && this.$store.state.user1 && this.$store.state.templates1) {
        this.dataMin = this.$store.state.user.created_at.split(' ')[0]
        this.calendarioMensal = this.$localStorage.get('calendar') === 'false' ? 0 : 1
        this.categoria = this.$store.state.calendarios.map(a => a.active ? a.id : '')
        this.SET_DATA('')
        this.calendarioMes(0, 1)
        let a = parseInt(this.dataMin)
        this.anos = []
        while (a <= this.year + 1) {
          this.anos.push(a++)
        }
        this.exportar.year = this.year
        this.exportar.month = parseInt(this.month)
        this.exportar.before = this.year + '-' + this.month + '-' + this.day
        this.exportar.after = this.year + '-' + this.month + '-' + this.day
      } else {
        _.delay(this.init, 100)
      }
    },
    mountCalendar () {
      let a = new Date(this.year + '/' + this.month + '/01')

      // quando encontrar segunda feira termina o ciclo
      while (a.getDay()) {
        a.setDate(a.getDate() - 1)
      }
      /*
      // meto a data a uma segunda feira
        while (a.getDay() !== 1) {
          a.setDate(a.getDate() - 1)
        }
        console.log(a.getDate(), '--')
        if (this.month === a.getMonth()) {
          // dia na primeira semana
          while (a.getDate() > 7) {
            a.setDate(a.getDate() - 7)
          }
        }
        console.log(a.getMonth(), '-.-')
        while (a.getDay() !== 1) {
        // encontro o dia de segunda feira
          a.setDate(a.getDate() - 1)
        }
        console.log(a.getMonth(), '-..-', a.getDate())
        if ((this.month - 1) === a.getMonth()) {
          a.setDate(a.getDate() - 7)
        }
      */
      this.semana0 = this.semana(a)
      this.semana1 = this.semana(a)
      this.semana2 = this.semana(a)
      this.semana3 = this.semana(a)
      this.semana4 = this.semana(a)
      this.semana5 = this.semana(a)
    },
    semana (a) {
      let i = 0
      let b = []
      while (i < 7) {
        b.push(a.getDate())
        a.setDate(a.getDate() + 1)
        ++i
      }
      return b
    },
    onDayClick (dia) {
      if (!dia.id) {
        let a = new Date()
        this.month = ('0' + (1 + a.getMonth())).slice(-2)
        this.year = a.getFullYear()
        this.day = ('0' + a.getDate()).slice(-2)
        dia = { id: this.year + '-' + this.month + '-' + this.day }
      }
      this.attributes[0].dates = dia.id
      this.$refs.calendar.move(dia.id)
      this.month = dia.id.split('-')[1]
      this.year = dia.id.split('-')[0]
      this.day = dia.id.split('-')[2]
      this.calendarioMes()
      if (this.caixa) {
        this.caixaFN(1)
      }
      if (this.monitor) {
        this.monitorFN(1)
      }
      this.$refs.calendar.move(dia)
    },
    selectDay (dia, semana) {
      this.day = dia
      if (semana === 0) {
        if (dia > 20) {
          if ((this.month - 1) > 0) {
            this.month -= 1
          } else {
            this.month = 12
            --this.year
          }
        }
      }
      if (semana === 5 || semana === 4) {
        if (dia < 13) {
          if ((parseInt(this.month) + 1) <= 12) {
            ++this.month
          } else {
            this.month = 1
            ++this.year
          }
        }
      }
      this.calendarioMes()
    },
    calendarioMes (diaa, atualizar) {
      this.reset()
      if (diaa) {
        if (parseInt(this.month) === parseInt(new Date(this.year + '/' + this.month + '/' + this.day).getMonth() + 1)) {
          this.day = new Date().getDate()
        } else {
          this.day = '1'
        }
      }
      if (this.day < 10) {
        this.day = '0' + parseInt(this.day)
      }
      this.SET_LOAD(true)
      if (this.month < 10) {
        this.month = '0' + parseInt(this.month)
      }
      let a
      let u = 0
      let i = 0
      while (i < 32) {
        this.dias[i++] = false
      }
      this.finalizados = false
      this.finished1 = false
      /*
      let a = new Date(this.year + '/' + this.month + '/01')
        a.setMonth(a.getMonth() + 1)
        a.setDate(a.getDate() - 1)
      */
      // searchEvent(this.axios, this.categoria, this.year, this.month, this.cor, this.before, this.after, this.day, this.finished).then((res) => {
      // eventos do mes todo
      // this.cor, this.year + '-' + this.month + '-01', this.year + '-' + this.month + '-' + a.getDate()
      if (!(parseInt(this.mesAntes) === parseInt(this.month) && parseInt(this.anoAntes) === parseInt(this.year)) || atualizar) {
        this.mesAntes = this.month
        this.anoAntes = this.year
        searchEvent(this.axios, [5, 97], this.year, this.month, this.cor, this.before, this.after, '', this.finished).then((res) => {
          this.notas = res.data.filter(a => parseInt(a.calendar_id) === 97)
          res.data = res.data.filter(a => a.title !== 'BalizasLandia' &&  parseInt(a.calendar_id) === 5)
          u = res.data.length
          i = 0
          let x
          // && parseInt(new Date(this.res[i].event_at).getDate()) === parseInt(this.day)
          while (i < u) {
            res.data[i].day = parseInt(res.data[i].event_at.substring(8, 10))

            x = res.data[i].event_at.split(' ')
            if (parseInt(x[1].substring(3, 5)) === 0) {
              res.data[i].hora = x[1].substring(0, 2) + 'h'
            } else {
              res.data[i].hora = x[1].substring(0, 5)
            }

            this.dias[res.data[i].day] = true
            if (res.data[i].day === parseInt(this.day)) {
              if (res.data[i].finished) {
                this.finalizados = true
              } else {
                this.finalizados = false
              }
            }
            ++i
          }
          if (this.finalizados) {
            this.finished1 = true
          }
          this.res = res.data
          this.mountCalendar()
          this.SET_LOAD()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        u = this.res.length
        i = 0
        // && parseInt(new Date(this.res[i].event_at).getDate()) === parseInt(this.day)
        while (i < u) {
          a = parseInt(this.res[i].event_at.substring(8, 10))
          this.dias[a] = true
          if (a === parseInt(this.day)) {
            if (this.res[i].finished) {
              this.finalizados = true
            } else {
              this.finalizados = false
            }
          }
          ++i
        }
        if (this.finalizados) {
          this.finished1 = true
        }
        this.SET_LOAD()
      }
    },
    apagar (dt) {
      this.$modal.show('dialog', {
        title: this.l('apagar_titulo'),
        text: this.l('apagar_descricao'),
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
              this.$modal.show('opcoesEventos')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              deleteEvent(this.axios, dt.id).then(() => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: this.l('apagar_sucesso') })
                this.SET_LOAD()
                this.res.splice(this.eventoInfoIndex, 1)
              }, (er) => {
                this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
              })
            },
            class: 'btn btn-danger'
          }
        ]
      })
    },
    upCalendarioMensal () {
      this.calendarioMensal = !this.calendarioMensal
      this.$localStorage.set('calendar', this.calendarioMensal)
    },
    selectDayCalendar (dt) {
      this.day = dt
      this.calendarioMes()
      this.SET_DATA(this.year + '-' + this.month + '-' + this.day)
    },
    calendarioDia (diaa) {
      // se diaa === 1 aumenta 1 dia, senao tira 1 dia
      let a = new Date(this.year + '/' + this.month + '/' + this.day)
      if (diaa) {
        a.setDate(a.getDate() + 1)
        this.day = a.getDate()
        this.month = a.getMonth() + 1
        this.year = a.getFullYear()
      } else {
        a.setDate(a.getDate() - 1)
        this.day = a.getDate()
        this.month = a.getMonth() + 1
        this.year = a.getFullYear()
      }
      this.calendarioMes()
    },
    pesqContactos: _.debounce(function (val) {
      if (val.length === 0) {
        this.contactosPesquisar = []
      } else {
        this.isLoadingcontacto = true
        searchClient(this.axios, val).then((res) => {
          this.isLoadingcontacto = false
          this.contactosPesquisar = res.data.data
        }, () => {
          this.isLoadingcontacto = false
        })
      }
    }, 550),
    openModalFN (dtt, ind) {
      this.eventoInfo = dtt
      var  tab = ['', '']
      if (this.meta(dtt, 'Outros Extras')) {
        tab = this.meta(dtt, 'Outros Extras').split('###')
      }
      this.form = {
        pp: this.meta(dtt, 'PP') || 0,
        pagou: this.meta(dtt, 'pagou') || 0,
        total1: this.meta(dtt, 'Total1') || 0,
        total2: this.meta(dtt, 'Total2') || 0,
        obs1: this.meta(dtt, 'Obvs'),
        n: tab[0].split('#:'),
        v: tab[1].split('#:')
      }
      this.eventoInfoIndex = ind
      this.$modal.show('opcoesEventos')
    },
    atualizar () {
      this.SET_LOAD(true)
      patchEventClient(this.axios, {
        id: this.eventoInfo.id,
        calendar_id: this.eventoInfo.calendar_id,
        color: this.eventoInfo.color,
        event_at: this.eventoInfo.event_at.substring(0, 16),
        tipo_jogo: this.eventoInfo.tipo_jogo,
        meta: {
          'PP': this.form.pp,
          'pagou': this.form.pagou,
          'Total1': this.form.total1,
          'Total2': this.form.total2,
          'Obvs': this.form.obs1,
          'Outros Extras': this.form.n.join('#:') + '###' + this.form.v.join('#:')
        }
      }).then(() => {
        this.SET_TOAST({ msg: 'Atualizado com sucesso.' })
        this.SET_LOAD()
        this.$modal.hide('opcoesEventos')
        this.calendarioMes(null, 1)
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    caixaFN (op) {
      this.monitor = 0
      if (!op) {
        this.caixa = !this.caixa
      }
      if (this.caixa) {
        this.SET_LOAD(true)
        searchEvent(this.axios, [92, 5], this.year, this.month, '', '', '', this.day, '').then((res) => {
          let caixa = res.data.filter(a => parseInt(a.calendar_id) === 92)
          if (caixa.length) {
            this.caixa1 = {
              num1: this.meta(caixa[0], 'Total1'),
              trans1: this.meta(caixa[0], 'Total2'),
              obs1: this.meta(caixa[0], 'Observações Lanche'),
              num2: this.meta(caixa[0], 'Anos'),
              trans2: this.meta(caixa[0], 'Anos 2'),
              obs2: this.meta(caixa[0], 'observacoes_menu'),
              id: caixa[0].id,
              nome: this.meta(caixa[0], 'tarde'),
              fecho1: (this.meta(caixa[0], 'Total1') > 0 || this.meta(caixa[0], 'Total2') > 0 || this.meta(caixa[0], 'Anos') > 0 || this.meta(caixa[0], 'Anos 2')) && this.$store.state.user.email !== 'fserra18@gmail.com' && this.$store.state.user.email !== 'vania_ss@hotmail.com'
            }
          } else {
            this.caixa1 = {
              num1: '',
              trans1: '',
              obs1: '',
              num2: '',
              trans2: '',
              obs2: '',
              nome: '',
              fecho1: 0
            }
          }
          let balizas = res.data.filter(a => parseInt(a.calendar_id) === 5)
          let i = 0
          let relatorios = {
            q1: 0,
            n1: 0,
            t1: 0,
            q2: 0,
            n2: 0,
            t2: 0,
            q3: 0,
            n3: 0,
            t3: 0,
            q4: 0,
            n4: 0,
            t4: 0,
            q5: 0,
            n5: 0,
            t5: 0
          }
          while (balizas[i]) {
            if (balizas[i].tipo_jogo) {
              switch (parseInt(balizas[i].tipo_jogo)) {
                case 1:
                  ++relatorios.q1
                  if (this.meta(balizas[i], 'Total1') > 0) {
                    relatorios.n1 += parseFloat(this.meta(balizas[i], 'Total1'))
                  }
                  if (this.meta(balizas[i], 'Total2') > 0) {
                    relatorios.t1 += parseFloat(this.meta(balizas[i], 'Total2'))
                  }
                  break
                case 2:
                  ++relatorios.q2
                  if (this.meta(balizas[i], 'Total1') > 0) {
                    relatorios.n2 += parseFloat(this.meta(balizas[i], 'Total1'))
                  }
                  if (this.meta(balizas[i], 'Total2') > 0) {
                    relatorios.t2 += parseFloat(this.meta(balizas[i], 'Total2'))
                  }
                  break
                case 3:
                  ++relatorios.q3
                  if (this.meta(balizas[i], 'Total1') > 0) {
                    relatorios.n3 += parseFloat(this.meta(balizas[i], 'Total1'))
                  }
                  if (this.meta(balizas[i], 'Total2') > 0) {
                    relatorios.t3 += parseFloat(this.meta(balizas[i], 'Total2'))
                  }
                  break
                case 4:
                  ++relatorios.q4
                  if (this.meta(balizas[i], 'Total1') > 0) {
                    relatorios.n4 += parseFloat(this.meta(balizas[i], 'Total1'))
                  }
                  if (this.meta(balizas[i], 'Total2') > 0) {
                    relatorios.t4 += parseFloat(this.meta(balizas[i], 'Total2'))
                  }
                  break
                case 5:
                  ++relatorios.q5
                  if (this.meta(balizas[i], 'Total1') > 0) {
                    relatorios.n4 += parseFloat(this.meta(balizas[i], 'Total1'))
                  }
                  if (this.meta(balizas[i], 'Total2') > 0) {
                    relatorios.t4 += parseFloat(this.meta(balizas[i], 'Total2'))
                  }
                  break
              }
            }
            ++i
          }
          this.relatorios = relatorios
          this.SET_LOAD()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    },
    caixaAtualizar () {
      if (!((this.caixa1.num1 > 0 || this.caixa1.trans1 > 0) && (this.caixa1.num2 > 0 || this.caixa1.trans2 > 0) && this.caixa1.nome)) {
        this.SET_TOAST({ msg: 'Tem que preencher um valor para poder guardar o registo', type: 'error' })
        return
      }
      let dd = new Date()
      let dia = this.day
      if (!(this.caixa1.id > 0)) {
        if (dd.getHours < 6) {
          dd = new Date(this.year + '-' + this.month + '-' + this.day)
          dd.setDate(dd.getDate() - 1)
          dia = dd.getDate()
        }
      }
      this.SET_LOAD(true)
      let dddd
      if (this.$store.state.user.email === 'fserra18@gmail.com' || this.$store.state.user.email === 'vania_ss@hotmail.com') {
        dddd = this.year + '-' + ('0' + this.month).slice(-2) + '-' + ('0' + dia).slice(-2) + ' ' + ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2)
      } else {
        if (dd.getHours() < 6) {
          dd.setDate(dd.getDate() - 1)
        }
        dddd = dd.getFullYear() + '-' + ('0' + (dd.getMonth() + 1)).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2) + ' ' + ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2)
      }
      let dta = {
        calendar_id: 92,
        color: '#ff0000',
        event_at: dddd,
        duration_minutes: 60,
        title: 'Contas Fecho Balizas',
        client_id: 10471,
        repeat: 'no',
        // observation: this.form.obs,
        meta: {
          'Total1': this.caixa1.num1,
          'Total2': this.caixa1.trans1,
          'Observações Lanche': this.caixa1.obs1,
          'Anos': this.caixa1.num2,
          'Anos 2': this.caixa1.trans2,
          'observacoes_menu': this.caixa1.obs2,
          tarde: this.caixa1.nome
        }
      }
      if (this.caixa1.id > 0) {
        dta.id = this.caixa1.id
        patchEventClient(this.axios, dta).then(() => {
          this.SET_TOAST({ msg: 'Registo atualizado com sucesso.' })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        createEventClient(this.axios, dta).then(() => {
          this.SET_TOAST({ msg: 'Registo adicionado com sucesso.' })
          this.caixa1.fecho1 = 1
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    },
    monitorFN (op) {
      this.caixa = 0
      if (!op) {
        this.monitor = !this.monitor
      }
      if (this.monitor) {
        this.SET_LOAD(true)
        if (this.users.length === 0) {
          empresaUsers(this.axios).then((res) => {
            this.users = res.data.filter(a => parseInt(a.balizas) === 1 && parseInt(a.ativo) === 1)
          }, () => {})
        }
        this.monitor1 = {
          entrada: '',
          saida: '',
          user: ''
        }
        searchEvent(this.axios, [93], this.year, this.month, '', '', '', this.day, '').then((res) => {
          this.monitores = res.data
          this.SET_LOAD()
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    },
    monitorAdd () {
      if (!(this.monitor1.user > 0)) {
        this.SET_TOAST({ msg: 'Tem que seleccionar o monitor', type: 'error' })
        return
      }
      if (!(this.monitor1.entrada && this.monitor1.saida)) {
        this.SET_TOAST({ msg: 'Tem que preencher o horário de entrada e de saída corretamente', type: 'error' })
        return
      }

      this.SET_LOAD(true)
      let x = this.users.find(a => parseInt(a.id) === parseInt(this.monitor1.user))
      let dd = new Date()
      let dta = {
        contact: {
          name: x.name,
          email: x.email,
          phone: x.phone
        },
        calendar_id: 93,
        color: '#ff0000',
        event_at: this.year + '-' + ('0' + this.month).slice(-2) + '-' + ('0' + this.day).slice(-2) + ' ' + ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2),
        duration_minutes: 60,
        title: 'Monitor Balizas',
        // client_id: this.monitor1.user,
        repeat: 'no',
        // observation: this.form.obs,
        meta: {
          'valor recebido': x.valor,
          'Anos 2': this.monitor1.entrada,
          'Anos 3': this.monitor1.saida
        }
      }
      createEventClient(this.axios, dta).then(() => {
        this.SET_TOAST({ msg: 'Registo adicionado com sucesso.' })
        this.monitorFN()
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    tpJogoFN (a) {
      switch (parseInt(a)) {
        case 1:
          return 'Jogo Fixo 42€'
        case 2:
          return 'Jogo Normal 48€'
        case 3:
          return 'Jogo LowCost Fixo 30€'
        case 4:
          return 'Jogo LowCost Normal 35€'
        case 5:
          return 'Festa BalizasLandia'
      }
      return ''
    },
    logout () {
      this.$auth.logout({
        makeRequest: false,
        params: {},
        redirect: '/login',
        success: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
          // window.location.assign('/')
          // window.location = window.location.protocol + '//' + window.location.hostname
        },
        error: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
        }
      })
    },
    editNota (dt, index) {
      this.notaID = dt
      this.notaID.index = index
      this.nota = dt.observation
      this.addNota = 1
    },
    GuardarNota () {
      if (this.nota.length < 6) {
        this.SET_TOAST({ msg: 'Tem que preencher uma nota cno mínimo com 6 caracteres', type: 'error' })
        return
      }
      this.SET_LOAD(true)
      if (this.notaID.id) {
        this.notaID.observation = this.nota
        this.notaID.event_at = this.year + '-' + this.month + '-' + this.day + ' 23:30'
        patchEventClient(this.axios, this.notaID).then((res) => {
          this.notas.splice(this.notaID.index, 1)
          this.notas.push(res.data)
          this.nota = ''
          this.addNota = 0
          this.SET_TOAST({ msg: 'Nota atualizada com sucesso', type: 'success' })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        createEventClient(this.axios, { calendar_id: 97, client_id: 17099, color: '#FCDC00', event_at: this.year + '-' + this.month + '-' + this.day + ' 23:30', duration_minutes: 30, observation: this.nota, title: 'Nota Balizas', repeat: 'no' }).then((res) => {
          this.notas.push(res.data)
          this.nota = ''
          this.addNota = 0
          this.SET_TOAST({ msg: 'Nota adicionada com sucesso', type: 'success' })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    },
    diaFN () {
      let d1 = new Date()
      let dd = new Date(this.year + '-' + this.month + '-' + this.day)
      dd.setDate(dd.getDate() + 1)
      dd.setHours(6)
      return dd >= d1
    }
  }
}
</script>
